import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { PropsWithChildren } from "react";
import { Field } from "react-final-form";
import {
  IsNotNullOrWhiteSpace,
  convertUTCDateToLocalTimezone,
  isNotNullAndUndefined,
  setZeroHours
} from "../../RAFComponents/helpers/utils";
import { RAFDatePickerViewFormat } from "../../constants/Common/Constants";
import { isRAFMaxDate, isRAFMinDate } from "../helpers/AppHelper";
import "./InputStyle.scss";
import RAFFieldLabel from "./RAFFieldLabel";
import { RAFFieldError, composeValidators } from "./RAFForm";
import { RAFDefaultFieldClassName, RAFDefaultFieldProps, RAFFieldProps, isRequired } from "./RFFUtils";

interface IProps {
  hideMinAndMaxValue?: boolean;
}

//const RAFDatePicker = ({ field, label, required }) => {

function RAFDatePicker<T>({
  field,
  label,
  width,
  onChanged,
  children,
  description,
  descriptionAsLabel,
  titleLocation,
  required = RAFDefaultFieldProps.required,
  showLabel = RAFDefaultFieldProps.showLabel,
  disabled = RAFDefaultFieldProps.disabled,
  showClearButton = RAFDefaultFieldProps.showClearButton,
  validate = RAFDefaultFieldProps.validate,
  ...props
}: PropsWithChildren<RAFFieldProps<T> & IProps>) {
  let datePickerObj: DatePickerComponent;
  // let labelClassName = isNotNullAndUndefined(props.labelClassName)
  //   ? " " + props.labelClassName
  //   : "";
  let labelClassName = isNotNullAndUndefined(props.labelClassName)
    ? props.labelClassName
    : "";
  let rowClassName = isNotNullAndUndefined(props.rowClassName)
    ? `${props.rowClassName} row`
    : RAFDefaultFieldClassName.rowClassName;
  let inputFieldClassName = isNotNullAndUndefined(props.inputFieldClassName)
    ? props.inputFieldClassName
    : 'col-12';
  if (titleLocation === 'Right') {
    rowClassName = `${rowClassName} flex-nowrap gx-2`;
    labelClassName = 'col-auto order-last';
    inputFieldClassName = 'col';
  }
  else if (titleLocation === 'Left') {
    rowClassName = `${rowClassName} flex-nowrap gx-2`;
    labelClassName = 'col-3';
    inputFieldClassName = 'col';
  }
  else if (titleLocation === 'Bottom') {
    labelClassName = 'order-last';
  }
  const getDisplayValueForDate = (inputValue) => {
    let retVal = null;
    if (IsNotNullOrWhiteSpace(inputValue)) {
      retVal = convertUTCDateToLocalTimezone(inputValue);
      if (
        isNotNullAndUndefined(props.hideMinAndMaxValue) &&
        props.hideMinAndMaxValue === true &&
        (isRAFMaxDate(inputValue, retVal) || isRAFMinDate(inputValue, retVal))
      ) {
        retVal = null;
      }
    }
    return retVal;
  };

  function removeFocus() {
    if (isNotNullAndUndefined(datePickerObj)) {
      datePickerObj.focusOut();
    }
  }

  const showTodayButton = field.toString().toLocaleLowerCase() === 'dob' || field.toString().toLocaleLowerCase() === 'dateOfbirth' ? false : true;

  return (
    <div
      className={
        isNotNullAndUndefined(props.formGroupClassName)
          ? props.formGroupClassName + " form-group"
          : "form-group"
      }
    >
      <div className={rowClassName} id={"rafdiv" + field.toString()}>
        {showLabel && showLabel === true && (
          <RAFFieldLabel
            field={field}
            label={label}
            required={required}
            labelClassName={labelClassName}
            description={description}
            descriptionAsLabel={descriptionAsLabel}
          ></RAFFieldLabel>
        )}
        <div className={inputFieldClassName}>
          <Field
            name={field.toString()}
            {...(props.initialValue
              ? { initialValue: props.initialValue }
              : {})}
            //validate={validate === true ? (required && isRequired) : null}
            {...(props.validators
              ? {
                validate:
                  validate === true
                    ? composeValidators(
                      required === true ? isRequired : null,
                      ...props.validators
                    )
                    : null,
              }
              : {
                validate:
                  validate === true
                    ? composeValidators(required === true ? isRequired : null)
                    : null,
              })}
            allowNull
            parse={(value) => (value === "" ? null : value)}
          >
            {({ input, meta }) => (
              <div>
                <DatePickerComponent
                  ref={(r) => (datePickerObj = r)}
                  id={`dpc${field.toString()}`}
                  name={input.name}
                  value={getDisplayValueForDate(input.value)}
                  change={(e) => {
                    if (e.isInteracted) {
                      input.onChange(setZeroHours(e.value));
                      removeFocus();
                      if (isNotNullAndUndefined(onChanged)) {
                        onChanged(setZeroHours(e.value));
                      }
                    }
                  }}
                  onBlur={(e) => {
                    datePickerObj.hide();
                    removeFocus();
                  }}
                  strictMode
                  //openOnFocus
                  format={RAFDatePickerViewFormat.DATE}
                  enableMask={true}
                  maskPlaceholder={{ day: 'DD', month: 'MM', year: 'YYYY' }}
                  width={width}
                  readonly={disabled}
                  disabled={disabled}
                  showClearButton={showClearButton}
                  //placeholder={props.placeholder}
                  placeholder={"DD/MM/YYYY"}
                  cssClass={
                    meta.error && meta.touched ? "inputFieldError" : null
                  }
                  showTodayButton={showTodayButton}
                //min={setZeroHours(moment().toDate())}
                //max={setZeroHours(moment().add(30, 'days').toDate())}
                >
                  {/* <Inject services={[MaskedDateTime]} /> */}
                </DatePickerComponent>
                {props.hideRequiredMessage !== true ? (
                  <RAFFieldError name={field.toString()} />
                ) : (
                  ""
                )}
              </div>
            )}
          </Field>
        </div>
      </div>
    </div>
  );
}

export default RAFDatePicker;
