import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { debounce } from "@syncfusion/ej2-base";
import * as React from "react";
import { Reducer, useReducer } from 'react';
import { Field } from "react-final-form";
import { Constants } from "../../constants/Common/Constants";
import { createInstance } from '../Utility/FormUtility';
import { getLookUpItems, getSearchedLookUpItems, getUniqueFilteredLookUpDropdownItems } from '../helpers/AutoCompleteMUIHelper';
import { IsNotNullOrWhiteSpace, isNotEmptyArray, isNotNullAndUndefined } from "../helpers/utils";
import { ILookupRow } from '../models/CompositeTypes/ILookupRow';
import { LookUpRow } from "../models/CompositeTypes/LookUpRow";
import RAFFieldLabel from "./RAFFieldLabel";
import { RAFFieldError, composeValidators } from "./RAFForm";
import { RAFDefaultFieldClassName, RAFDefaultFieldProps, RAFLookupFieldProps, hasEmoji, isRequired } from "./RFFUtils";

const defaultTake = 50;
const defaultSkip = 0;

interface IState {
    isLoading: boolean;
    dropdownState: boolean;
    dataSource: LookUpRow[];
    searchedText: string;
    prevSearchedText: string,
    skipItemsOnScroll: number;
}

function RAFAutoCompleteMUI<T>({
    field,
    label,
    width,
    created,
    onChanged,
    fieldInnerText,
    children,
    maxlength,
    description,
    descriptionAsLabel,
    titleLocation,
    required = RAFDefaultFieldProps.required,
    showLabel = RAFDefaultFieldProps.showLabel,
    disabled = RAFDefaultFieldProps.disabled,
    showClearButton = RAFDefaultFieldProps.showClearButton,
    validate = RAFDefaultFieldProps.validate,
    ...props }: React.PropsWithChildren<RAFLookupFieldProps<T>>) {
    const objLookupRow = isNotNullAndUndefined(props.type)
        ? (createInstance(props.type) as ILookupRow)
        : null;

    const url = isNotNullAndUndefined(props.url)
        ? Constants.baseAPIUrl + props.url
        : Constants.baseAPIUrl + objLookupRow.getLookupUrl();

    //style classNames start
    let labelClassName = isNotNullAndUndefined(props.labelClassName)
        ? props.labelClassName
        : "";
    let rowClassName = isNotNullAndUndefined(props.rowClassName)
        ? `${props.rowClassName} row`
        : RAFDefaultFieldClassName.rowClassName;
    let inputFieldClassName = isNotNullAndUndefined(props.inputFieldClassName)
        ? props.inputFieldClassName
        : "col-12";
    if (titleLocation === "Right") {
        rowClassName = `${rowClassName} flex-nowrap gx-2`;
        labelClassName = "col-auto order-last";
        inputFieldClassName = "col";
    } else if (titleLocation === "Left") {
        rowClassName = `${rowClassName} flex-nowrap gx-2`;
        labelClassName = "col-3";
        inputFieldClassName = "col";
    } else if (titleLocation === "Bottom") {
        labelClassName = "order-last";
    }
    //style classNames end


    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            isLoading: false,
            dropdownState: false,
            dataSource: [],
            searchedText: null,
            prevSearchedText: null,
            skipItemsOnScroll: 0,
        }
    );

    const onOpenAutoDropdown = () => {
        setState({ dropdownState: true, skipItemsOnScroll: 0 });
        getLookUpDroopDownItems();
    };

    const getLookUpDroopDownItems = async () => {
        if (isNotEmptyArray(state.dataSource)) {

        } else {
            if (isNotNullAndUndefined(state.searchedText)) {
                return;
            }
            setState({ isLoading: true });

            const lookUpDataItems = await getLookUpItems(
                url,
                defaultSkip,
                defaultTake,
                null,
                props.moduleName,
                props.customFilter,
                null
            );

            if (
                isNotEmptyArray(lookUpDataItems)
            ) {
                const data: LookUpRow[] = lookUpDataItems;

                const uniqueData = getUniqueFilteredLookUpDropdownItems(data);

                setState({ dataSource: uniqueData, isLoading: false });
            }
            else {
                setState({ dropdownState: false, dataSource: [], isLoading: false });
            }
        }
    };

    const handleInputChange = (event, value) => { //calls when text input changes
        if (isNotNullAndUndefined(event) && event.isTrusted === true && event.type === 'change') {
            setHandleInputDebounce(value);
        }
    };


    const setHandleInputDebounce = debounce(async (newSearchedText) => {
        setState({ isLoading: true, skipItemsOnScroll: defaultSkip });
        const searchedText = newSearchedText;

        const responseLookUpItems = await getSearchedLookUpItems(
            url,
            defaultSkip,
            defaultTake,
            searchedText,
            props.moduleName,
            props.customFilter,
            state.dataSource,
            state.prevSearchedText
        );

        const lookUpItems = isNotNullAndUndefined(responseLookUpItems) ? responseLookUpItems.lookUpDataItems : [];

        if (
            isNotEmptyArray(lookUpItems)
        ) {
            const data: LookUpRow[] = [...lookUpItems];

            const uniqueData = getUniqueFilteredLookUpDropdownItems(data);

            setState({ dataSource: uniqueData, prevSearchedText: searchedText, searchedText, isLoading: false });
        }
        else {
            setState({ dropdownState: false, dataSource: [], prevSearchedText: searchedText, searchedText, isLoading: false });
        }
    }, 500);

    const onChangeSearchText = (selectedValue: LookUpRow | string, input) => {
        if (typeof selectedValue === "string") {
            //on enter key press
            const existingItem =
                IsNotNullOrWhiteSpace(selectedValue) &&
                    isNotEmptyArray(state.dataSource)
                    ? state.dataSource.find(
                        (x) =>
                            isNotNullAndUndefined(x.Value) &&
                            x.Value.toLowerCase() ===
                            selectedValue.toLowerCase()
                    )
                    : null;

            if (isNotNullAndUndefined(existingItem)) {
                onChangeInput(existingItem, input);
            } else {
                onChangeInput(null, input, selectedValue);
            }
        } else {
            onChangeInput(selectedValue, input);
        }
    };

    const onChangeInput = (newValue: LookUpRow, input, inputText?: string) => {
        if (IsNotNullOrWhiteSpace(inputText)) {
            const prevValue = input.value;
            if (prevValue !== inputText) {
                input.onChange(inputText !== null ? inputText : null);
                if (isNotNullAndUndefined(onChanged)) {
                    onChanged(inputText, null);
                }
            }
        } else {
            const prevValue = input.value;
            const itemUID = isNotNullAndUndefined(newValue) ? newValue.UID : null;
            const itemValue = isNotNullAndUndefined(newValue) ? newValue.Value : null;
            if (prevValue !== itemValue) {
                input.onChange(itemValue !== null ? itemValue : null);
                if (isNotNullAndUndefined(onChanged)) {
                    onChanged(itemValue, itemUID);
                }
            }
        }
    };

    const loadMoreOptions = async () => {
        const { skipItemsOnScroll, searchedText, dataSource } = state;
        const newSkipItemsOnScroll = skipItemsOnScroll + defaultTake;
        if (isNotEmptyArray(dataSource) && newSkipItemsOnScroll > dataSource.length) {
            return;
        }

        const responseLookUpItems = await getSearchedLookUpItems(
            url,
            newSkipItemsOnScroll,
            defaultTake,
            searchedText,
            props.moduleName,
            props.customFilter,
            state.dataSource,
            state.prevSearchedText
        );

        const lookUpItems = isNotNullAndUndefined(responseLookUpItems) ? responseLookUpItems.lookUpDataItems : [];

        if (
            isNotEmptyArray(lookUpItems)
        ) {
            const responseData: LookUpRow[] = isNotEmptyArray(lookUpItems)
                ? [...lookUpItems]
                : [];

            const data = [...dataSource, ...responseData];

            const uniqueData = getUniqueFilteredLookUpDropdownItems(data);

            setState({ skipItemsOnScroll: newSkipItemsOnScroll, dataSource: uniqueData, prevSearchedText: searchedText, searchedText, isLoading: false });
        }
        else {
            setState({ skipItemsOnScroll: newSkipItemsOnScroll, dropdownState: false, dataSource: [], prevSearchedText: searchedText, searchedText, isLoading: false });
        }
    };

    const onCloseAutoDropdown = () => {
        setState({ skipItemsOnScroll: 0, dropdownState: false, isLoading: false, searchedText: null, prevSearchedText: null, dataSource: IsNotNullOrWhiteSpace(state.searchedText) ? [] : state.dataSource });
    };

    return (
        <div
            className={
                isNotNullAndUndefined(props.formGroupClassName)
                    ? props.formGroupClassName + " form-group"
                    : "form-group"
            }
        >
            <div className={rowClassName} id={"rafdiv" + field.toString()}>
                {showLabel && showLabel === true && (
                    <RAFFieldLabel
                        field={field}
                        label={label}
                        required={required}
                        labelClassName={labelClassName}
                        description={description}
                        descriptionAsLabel={descriptionAsLabel}
                    ></RAFFieldLabel>
                )}
                <Field
                    name={field.toString()}
                    {...(props.initialValue ? { initialValue: props.initialValue } : {})}
                    //validate={validate === true ? (required && isRequired) : null}
                    {...(props.validators
                        ? {
                            validate:
                                validate === true
                                    ? composeValidators(
                                        required === true ? isRequired : null,
                                        hasEmoji,
                                        ...props.validators
                                    )
                                    : null,
                        }
                        : {
                            validate:
                                validate === true
                                    ? composeValidators(
                                        required === true ? isRequired : null,
                                        hasEmoji
                                    )
                                    : null,
                        })}
                    allowNull
                    parse={(value) => (value === "" ? null : value)}
                >
                    {({ input }) => {
                        return (
                            <div className={inputFieldClassName}>
                                <div
                                // className={
                                //     isNotNullAndUndefined(fieldInnerText)
                                //         ? "fieldInnerText d-flex"
                                //         : "d-flex"
                                // }
                                >
                                    <Autocomplete
                                        id={input.name}
                                        value={input.value}

                                        freeSolo
                                        open={state.dropdownState}
                                        onOpen={() => {
                                            onOpenAutoDropdown();
                                        }}
                                        onClose={() => {
                                            onCloseAutoDropdown();
                                        }}
                                        options={state.dataSource}
                                        isOptionEqualToValue={(option, value) => option.Value === value}
                                        getOptionLabel={(option) =>
                                            typeof option === 'string' ? option : option.Value
                                        }
                                        getOptionKey={(option) =>
                                            typeof option === "string" ? option : option.UID
                                        }
                                        loading={state.isLoading}
                                        onInputChange={handleInputChange}
                                        onChange={(event, newValue) => {
                                            onChangeSearchText(newValue, input);
                                        }}
                                        size="small"
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                // label={props.placeholder}
                                                margin="none"
                                                variant="outlined"
                                                placeholder={props.placeholder}

                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {state.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                        ListboxProps={{
                                            onScroll: (event) => {
                                                const listboxNode = event.currentTarget;
                                                if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                                                    // The scroll has hit the bottom, load more options
                                                    loadMoreOptions();
                                                }
                                            },
                                        }}
                                        onBlur={(event) => {
                                            //console.log('onBlur', event);
                                            const searchText = event.target['value'];
                                            onChangeSearchText(searchText, input);
                                        }}
                                    />
                                </div>

                                {props.hideRequiredMessage !== true ? (
                                    <RAFFieldError name={field.toString()} />
                                ) : (
                                    ""
                                )}
                            </div>
                        );
                    }}
                </Field>
            </div>
        </div>
    );
};

export default RAFAutoCompleteMUI;