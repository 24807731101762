import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { IconButton, InputAdornment } from "@mui/material";
import { DesktopTimePicker, LocalizationProvider, MobileTimePicker, PickersActionBarAction } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import React, { PropsWithChildren } from "react";
import {
    IsNotNullOrWhiteSpace,
    IsNullOrWhiteSpace,
    convertUTCDateToLocalTimezone,
    isDate
} from "../../RAFComponents/helpers/utils";
import {
    isNotNullAndUndefined
} from "../helpers/utils";
import "./InputStyle.scss";

//MUI_mobile_time_picker start
const is12AmTime = (time) => {
    let is12AM = false;
    if (isNotNullAndUndefined(time)) {
        const date = new Date(time);
        if (date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0) {
            is12AM = false;// true;
        }
    }
    return is12AM;
};

const getComponentValueInMoment = (inputValue) => {
    if (IsNullOrWhiteSpace(inputValue)) {
        return null;
    }
    const formValue = inputValue;
    const momentObj = moment.parseZone(formValue);
    //const isUtc = momentObj.utcOffset() === 0;
    const isLocal = momentObj.utcOffset() !== 0;

    if (isLocal === true) {
        if (is12AmTime(formValue)) {
            return null;
        } else {
            return moment(formValue);
        }
    } else {
        let retVal = convertUTCDateToLocalTimezone(inputValue);
        if (IsNotNullOrWhiteSpace(inputValue)) {
            if (
                moment(retVal, moment.ISO_8601, true).isValid() &&
                !isDate(retVal)
            ) {
                if (is12AmTime(formValue)) {
                    return null;
                } else {
                    return moment(new Date(retVal + "Z"));
                }
            }
        }
        if (is12AmTime(formValue)) {
            return null;
        } else {
            return isNotNullAndUndefined(retVal) ? moment(retVal) : null;
        }
    }
};
//MUI_mobile_time_picker end

interface IProps {
    fieldName: string;
    inputvalue: Date;
    showClearButton: boolean;
    interval: number;
    roundOff: boolean;
    disabled: boolean;

    timePickerampm?: boolean;
    minTimeStateValue: any;
    maxTimeStateValue: any;

    onChangeTimePicker: (value: Date) => void;
}

function RAFCustomResponsiveTimePicker({
    fieldName,
    disabled,
    inputvalue,
    showClearButton,
    roundOff = false,
    interval = 5,
    timePickerampm = true,
    minTimeStateValue,
    maxTimeStateValue,
    ...props }: PropsWithChildren<IProps>) {

    const [openMobile, setOpenMobile] = React.useState(false);

    const intervalValue = isNotNullAndUndefined(interval) ? interval : 5;
    const muiDateTimePickerActionsBtns: PickersActionBarAction[] = showClearButton === false ? ["cancel", 'accept'] : ['clear', "cancel", 'accept'];

    const onChangeTimePicker = (e) => {
        const value = isNotNullAndUndefined(e) ? e['_d'] : null;
        const _isValid = isNotNullAndUndefined(value) ? e['_isValid'] : false;

        if (IsNullOrWhiteSpace(value)) {
            if (props.onChangeTimePicker) {
                props.onChangeTimePicker(null);
            }
        } else if (_isValid) {
            if (props.onChangeTimePicker) {
                props.onChangeTimePicker(value);
            }
        }
    };

    const handleMobileOpen = () => {
        if (disabled) return;
        setOpenMobile(true);
    };

    const handleMobileClose = () => {
        setOpenMobile(false);
    };

    const displayValue = getComponentValueInMoment(inputvalue);

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopTimePicker
                    className="raf_time_picker raf_mui_input"
                    value={displayValue}
                    name={fieldName}
                    autoFocus={false}
                    onChange={(e) => {
                        onChangeTimePicker(e);
                    }}
                    slots={{
                        openPickerButton: () => (
                            <InputAdornment position="end">
                                <IconButton onClick={handleMobileOpen}>
                                    <AccessTimeIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    slotProps={{
                        textField: {
                            InputProps: {
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleMobileOpen}>
                                            <AccessTimeIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            },
                        },
                        field: { clearable: showClearButton === true ? true : false }
                    }}
                    {...((roundOff === true)
                        ? { minutesStep: intervalValue }
                        : {})}
                    ampm={timePickerampm}
                    disabled={disabled}
                    {...(isNotNullAndUndefined(minTimeStateValue)
                        ? { minTime: minTimeStateValue }
                        : {})}
                    {...(isNotNullAndUndefined(maxTimeStateValue)
                        ? { maxTime: maxTimeStateValue }
                        : {})}
                // PopoverProps={{
                //   container: () => document.getElementById('my-container'),
                // }}


                //ampmInClock={true}                           
                // {...((isNotNullAndUndefined(minTimeStateValue) || isNotNullAndUndefined(maxTimeStateValue))
                //   ? { disableIgnoringDatePartForTimeValidation: true }
                //   : {})}
                />
                {openMobile && ( //do not remove this div and comment this open on icon click from desktop time picker
                    <div style={{ display: 'none' }}>
                        <MobileTimePicker
                            open={openMobile}
                            onClose={handleMobileClose}
                            className="raf_time_picker raf_mui_input"
                            value={displayValue}
                            name={fieldName}
                            autoFocus={false}
                            onChange={(e) => {
                                onChangeTimePicker(e);
                            }}
                            slotProps={{
                                actionBar: {
                                    actions: muiDateTimePickerActionsBtns
                                },
                                field: { clearable: showClearButton === true ? true : false }
                            }}
                            {...((roundOff === true)
                                ? { minutesStep: intervalValue }
                                : {})}
                            ampm={true}
                            disabled={disabled}

                            {...(isNotNullAndUndefined(minTimeStateValue)
                                ? { minTime: minTimeStateValue }
                                : {})}
                            {...(isNotNullAndUndefined(maxTimeStateValue)
                                ? { maxTime: maxTimeStateValue }
                                : {})}
                        />
                    </div>
                )}
            </LocalizationProvider>
        </>
    );
}

export default React.memo(RAFCustomResponsiveTimePicker);