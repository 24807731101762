import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import moment from "moment";
import { PropsWithChildren, useContext, useEffect } from "react";
import { Field, FormRenderProps } from "react-final-form";
import {
  CalculateDate,
  Guid,
  IsNullOrWhiteSpace,
  convertUTCDateToLocalTimezone,
  isNotNullAndUndefined,
} from "../../RAFComponents/helpers/utils";
import { Constants } from "../../constants/Common/Constants";
import "./InputStyle.scss";
import RAFChoiceOption from "./RAFChoiceOption";
import RAFDatePicker from "./RAFDatePicker";
import RAFDatePickerMUI from "./RAFDatePickerMUI";
import RAFFieldLabel from "./RAFFieldLabel";
import { RAFFieldError, composeValidators } from "./RAFForm";
import RAFRadioButtonList from "./RAFRadioButtonList";
import {
  RAFDatePickerFieldProps,
  RAFDefaultFieldClassName,
  RAFDefaultFieldProps,
  RAFFormContext,
  isRequired,
  setFormValue,
} from "./RFFUtils";

interface IProps {
  secondaryField?: string;
  showEmpty?: boolean;
  showClearTextBtn?: boolean;
  allowMaxValue?: boolean;
  hideMinAndMaxValue?: boolean;
  displayStyle?: "default" | "splitButton";
  dropdownDateOptions?: { id: number; text: string; value: string; }[];
}
//const RAFDatePicker = ({ field, label, required }) => {

function RAFDatePickerCustom<T>({
  field,
  label,
  onChanged,
  children,
  description,
  descriptionAsLabel,
  displayStyle = "default",
  showClearButton = false,
  required = RAFDefaultFieldProps.required,
  showLabel = RAFDefaultFieldProps.showLabel,
  disabled = RAFDefaultFieldProps.disabled,
  validate = RAFDefaultFieldProps.validate,
  ...props
}: PropsWithChildren<RAFDatePickerFieldProps<T> & IProps>) {
  let dateObj: DatePickerComponent;
  const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);
  let labelClassName = isNotNullAndUndefined(props.labelClassName)
    ? props.labelClassName
    : "";

  const onDateClicked = (days?: string) => {
    let calculatedDate;
    calculatedDate = CalculateDate(days);
    setFormValue(
      rafFormContextValue,
      field.toString(),
      calculatedDate !== null ? calculatedDate : null
    );
    if (isNotNullAndUndefined(onChanged)) onChanged(calculatedDate);
  };

  //defalut_Style methods start
  const itemIndexKey = Guid.newGuid();

  let dropdownDateOptions =
    isNotNullAndUndefined(props.dropdownDateOptions) &&
      props.dropdownDateOptions.length > 0
      ? props.dropdownDateOptions
      : [
        { id: 1, text: "Today", value: "0" },
        { id: 2, text: "Tomorrow", value: "1" },
        { id: 3, text: "+3 days", value: "3" },
        { id: 4, text: "+7 days", value: "7" },
        { id: 5, text: "+15 days", value: "15" },
      ];

  useEffect(() => {
    if (props.showEmpty) {
      if (
        isNotNullAndUndefined(dropdownDateOptions) &&
        dropdownDateOptions.length > 0
      ) {
        dropdownDateOptions.splice(0, 0, {
          id: 0,
          text: "Unplanned",
          value: "NoDate",
        });
      } else {
        dropdownDateOptions = [{ id: 1, text: "Unplanned", value: "NoDate" }];
      }
    }
  }, [dropdownDateOptions]);

  function customDateBtnClicked(dateField) {
    for (let i = 0; i < dropdownDateOptions.length; i++) {
      let drodownBtnComponentCustom = document.getElementById(
        `divCustomDate_${itemIndexKey}_${dropdownDateOptions[i].id}`
      );
      if (isNotNullAndUndefined(drodownBtnComponentCustom)) {
        drodownBtnComponentCustom.onclick = () => {
          onClickDateItemClicked(dropdownDateOptions[i].value, dateField);
        };
      }
    }
  }

  const onClickDateItemClicked = (days: string, dateField: string) => {
    let calculatedDate;
    if (days !== "Custom") {
      calculatedDate = CalculateDate(days);
      setFormValue(rafFormContextValue, dateField, calculatedDate);
      if (isNotNullAndUndefined(onChanged)) onChanged(calculatedDate);
      let customDateBtn = document.getElementsByClassName("reminderDateBtn");
      for (let i = 0; i < customDateBtn.length; i++) {
        customDateBtn[i].classList.remove("e-active");
        customDateBtn[i].classList.remove("e-popup-open");
        customDateBtn[i].classList.add("e-popup-close");
      }
    }
  };

  const closeDatePicker = (fieldIndex) => {
    let dropdownItemDiv = "dropdownItemDiv_" + fieldIndex;
    let dateCustomPopupDiv = "dateCustomPopupDiv_" + fieldIndex;

    if (isNotNullAndUndefined(document.getElementById(dropdownItemDiv))) {
      document.getElementById(dropdownItemDiv).classList.remove("hidden");
    }
    if (isNotNullAndUndefined(document.getElementById(dateCustomPopupDiv))) {
      document.getElementById(dateCustomPopupDiv).classList.add("hidden");
    }
  };

  const onClearDate = () => {
    onClickDateItemClicked(
      props.allowMaxValue ? "NoDate" : null,
      field.toString()
    );

    // setFormValue(rafFormContextValue, field, null);
  };

  const getDisplayValueForDate = (inputValue) => {
    let retVal = null;
    if (isNotNullAndUndefined(inputValue)) {
      let localDate = convertUTCDateToLocalTimezone(inputValue);
      retVal = moment(localDate).format("DD/MM/YYYY");
      if (props.showEmpty) {
        if (
          Constants.MaxDate.valueOf() === localDate.valueOf() ||
          Constants.MaxDate.valueOf() === inputValue.valueOf()
        ) {
          retVal = "Unplanned";
        }
      }
    } else {
      retVal = props.showEmpty ? "Unplanned" : "Not set";
    }
    return retVal;
  };
  //defalut_Style methods end

  return (
    <div
      className={
        isNotNullAndUndefined(props.formGroupClassName)
          ? props.formGroupClassName + " form-group"
          : "form-group"
      }
    >
      <div
        className={RAFDefaultFieldClassName.rowClassName}
        id={"rafdiv" + field.toString()}
      >
        {showLabel && showLabel === true && (
          <RAFFieldLabel
            field={field}
            label={label}
            required={required}
            labelClassName={labelClassName}
            description={description}
            descriptionAsLabel={descriptionAsLabel}
            rightSection={
              props.showClearTextBtn === true && (
                <ButtonComponent
                  className="bg-transparent border-0 p-0 content_state_danger_base ms-auto"
                  content="Clear"
                  type="button"
                  onClick={() => onClearDate()}
                ></ButtonComponent>
              )
            }
          ></RAFFieldLabel>
        )}
        <div
          className={
            props.inputFieldClassName ? props.inputFieldClassName : "col-12"
          }
        >
          {displayStyle === "splitButton" ? (
            <div className="row">
              <div className="col-12">
                <RAFDatePickerMUI
                  field={field.toString()}
                  showLabel={false}
                  label="Due Date"
                  //   disabled={
                  //     taskRow && taskRow.TaskStatus === RAFActionStatus.Planned
                  //       ? false
                  //       : true
                  //   }
                  placeholder={
                    isNotNullAndUndefined(label) ? label : field.toString()
                  }
                  onChanged={(value) => {
                    if (isNotNullAndUndefined(onChanged)) onChanged(value);
                  }}
                  hideMinAndMaxValue={props.hideMinAndMaxValue}
                  showClearButton={showClearButton ?? false}
                ></RAFDatePickerMUI>
                {!IsNullOrWhiteSpace(props.secondaryField) && (
                  <div className="customPicker mt-2">
                    <RAFRadioButtonList
                      field={props.secondaryField.toString()}
                      formGroupClassName="taskRadioBtn"
                      radioButtonSM
                      showLabel={false}
                      onChanged={(value) => onDateClicked(value)}
                      validate={false}
                      uitype={props.uitype ? props.uitype : "quickselect"}
                      radioBtnRowClassName="gx-2 remove-min-width"
                      radioBtnClassName="col-auto raf-sm primary-radio-btn custom-radio-btn-m-0"
                    >
                      {/*<RAFChoiceOption label="ASAP" value="ASAP" />*/}
                      {dropdownDateOptions.map((item, index) => {
                        return (
                          <RAFChoiceOption
                            key={index}
                            label={item.text}
                            value={item.value}
                          />
                        );
                      })}
                      {/* <RAFChoiceOption label="Unplanned" value="NoDate" />
                                            <RAFChoiceOption label="Today" value="0" />
                                            <RAFChoiceOption label="Tomorrow" value="1" />
                                            <RAFChoiceOption label="+3 days" value="3" />
                                            <RAFChoiceOption label="+7 days" value="7" />
                                            <RAFChoiceOption label="+15 days" value="15" /> */}
                      {/*<RAFChoiceOption label="+30 days" value="30" />
                                        <RAFChoiceOption label="NoDate" value="NoDate" />*/}
                    </RAFRadioButtonList>
                    {/* <div className="hidden">
                                                        <span onClick={() => onDateClicked('Custom')}>Custom</span>
                                                        <div className="customDatePicker hidden">
                                                            <DatePickerComponent placeholder="Choose a date" ref={scope => dateObj = scope}
                                                                name={input.name} value={input.value}
                                                                change={(e) => {
                                                                    input.onChange(setZeroHours(e.value));
                                                                    if (isNotNullAndUndefined(onChanged)) {
                                                                        onChanged(setZeroHours(e.value));
                                                                    }
                                                                }}
                                                                format={RAFDatePickerViewFormat.DATE}
                                                                cssClass={meta.error && meta.touched ? "inputFieldError cpicker" : "cpicker"}
                                                            />
                                                        </div>
                                                        <div>
                                                        </div>
                                                    </div> */}

                    {props.hideRequiredMessage !== true ? (
                      <RAFFieldError name={field.toString()} />
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <Field
              name={field.toString()}
              {...(props.initialValue
                ? { initialValue: props.initialValue }
                : {})}
              //validate={validate === true ? (required && isRequired) : null}
              {...(props.validators
                ? {
                  validate:
                    validate === true
                      ? composeValidators(
                        required === true ? isRequired : null,
                        ...props.validators
                      )
                      : null,
                }
                : {
                  validate:
                    validate === true
                      ? composeValidators(
                        required === true ? isRequired : null
                      )
                      : null,
                })}
              allowNull
              parse={(value) => (value === "" ? null : value)}
            >
              {({ input, meta }) => {
                return (
                  <div className="customPicker">
                    <div
                      className="customViewDropdownMenu"
                      id={`divCustomDateOuter_${itemIndexKey}`}
                    >
                      {dropdownDateOptions.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="dropdown-item"
                            id={`divCustomDate_${itemIndexKey}_${item.id}`}
                          >
                            <span>{item.text}</span>
                          </div>
                        );
                      })}
                      <div className="border-top"></div>
                      <div className="dropdown-item">
                        <div className="noMarginBottom">
                          <RAFDatePicker
                            field={field.toString()}
                            formGroupClassName="m-0"
                            placeholder="Custom Date"
                            showLabel={false}
                            onChanged={(value) => {
                              if (isNotNullAndUndefined(onChanged))
                                onChanged(value);
                            }}
                          ></RAFDatePicker>
                        </div>
                      </div>
                    </div>
                    <div className="w-100">
                      <DropDownButtonComponent
                        target={`#divCustomDateOuter_${itemIndexKey}`}
                        cssClass={"reminderDateBtn customScrollBar"}
                        onClick={() => customDateBtnClicked(field.toString())}
                        disabled={disabled}
                      >
                        {getDisplayValueForDate(input.value)}
                      </DropDownButtonComponent>
                    </div>
                    <div
                      className="custom-popup-div reminderDatePopup hidden"
                      id={`dateCustomPopupDiv_${field.toString()}`}
                    >
                      <RAFDatePicker
                        field={field.toString()}
                        showLabel={false}
                        onChanged={(value) => {
                          if (isNotNullAndUndefined(onChanged))
                            onChanged(value);
                        }}
                      ></RAFDatePicker>
                      <div className="row mt-2 justify-content-end">
                        <div className="col-auto">
                          <ButtonComponent
                            type="button"
                            content="Ok"
                            cssClass="e-info"
                            onClick={() => closeDatePicker(field.toString())}
                          ></ButtonComponent>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }}
            </Field>
          )}
        </div>
      </div>
    </div>
  );
}

export default RAFDatePickerCustom;
