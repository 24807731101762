import { DesktopTimePicker, LocalizationProvider, PickersActionBarAction, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import moment from "moment";
import { PropsWithChildren, ReactNode, useEffect, useState } from "react";
import { Field } from "react-final-form";
import {
  Guid,
  IsNotNullOrWhiteSpace,
  IsNullOrWhiteSpace,
  convertUTCDateToLocalTimezone,
  getUTCDateValue,
  isDate,
  isNotNullAndUndefined,
  isNullOrUndefined,
  roundToNearestInterval
} from "../../RAFComponents/helpers/utils";
import { RAFDatePickerViewFormat } from "../../constants/Common/Constants";
import { RAFUIType } from "../models/Common/RAFDataType";
import "./InputStyle.scss";
import RAFCustomResponsiveDatePicker from "./RAFCustomResponsiveDatePicker";
import RAFCustomResponsiveTimePicker from "./RAFCustomResponsiveTimePicker";
import RAFFieldLabel from "./RAFFieldLabel";
import { RAFFieldError, composeValidators } from "./RAFForm";
import { RAFDefaultFieldClassName, RAFDefaultFieldProps, RAFFieldProps, isRequired } from "./RFFUtils";

//MUI_mobile_time_picker start
const is12AmTime = (time) => {
  let is12AM = false;
  if (isNotNullAndUndefined(time)) {
    const date = new Date(time);
    if (date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0) {
      is12AM = false;// true;
    }
  }
  return is12AM;
};

const getComponentValueInMoment = (inputValue) => {
  if (IsNullOrWhiteSpace(inputValue)) {
    return null;
  }
  const formValue = inputValue;
  const momentObj = moment.parseZone(formValue);
  //const isUtc = momentObj.utcOffset() === 0;
  const isLocal = momentObj.utcOffset() !== 0;

  if (isLocal === true) {
    if (is12AmTime(formValue)) {
      return null;
    } else {
      return moment(formValue);
    }
  } else {
    let retVal = convertUTCDateToLocalTimezone(inputValue);
    if (IsNotNullOrWhiteSpace(inputValue)) {
      if (
        moment(retVal, moment.ISO_8601, true).isValid() &&
        !isDate(retVal)
      ) {
        if (is12AmTime(formValue)) {
          return null;
        } else {
          return moment(new Date(retVal + "Z"));
        }
      }
    }
    if (is12AmTime(formValue)) {
      return null;
    } else {
      return isNotNullAndUndefined(retVal) ? moment(retVal) : null;
    }
  }
};
//MUI_mobile_time_picker end

interface IProps {
  displayStyle?: 'SplitStyle' | 'TimePicker' | 'DesktopTimePicker' | 'Default';
  uiType?: string;
  minDateValue?: Date;
  maxDateValue?: Date;

  interval?: number;
  roundOff?: boolean;
  timePickerampm?: boolean;
  allowKeyboardInteraction?: boolean;
  labelRightSection?: React.ReactFragment | ReactNode;

  disableDatePicker?: boolean;
}

function RAFDateTimePicker<T>({
  field,
  label,
  width,
  disableDatePicker,
  onChanged,
  children,
  description,
  descriptionAsLabel,
  titleLocation,


  displayStyle = 'SplitStyle',
  uiType = RAFUIType.DateTime,

  roundOff = false,
  interval = 5,
  allowKeyboardInteraction = false,

  required = RAFDefaultFieldProps.required,
  showLabel = RAFDefaultFieldProps.showLabel,
  disabled = RAFDefaultFieldProps.disabled,
  showClearButton = RAFDefaultFieldProps.showClearButton,
  validate = RAFDefaultFieldProps.validate,
  timePickerampm = true,
  ...props
}: PropsWithChildren<RAFFieldProps<T> & IProps>) {
  const guid = Guid.newGuid();
  const intervalValue = isNotNullAndUndefined(interval) ? interval : 5;

  let dateTimePickerObj: DateTimePickerComponent;

  const [minTimeStateValue, setminTimeStateValue] = useState(null);
  const [maxTimeStateValue, setmaxTimeStateValue] = useState(null);

  useEffect(() => {
    const minTimeValue = getMinDateTimeMobileTimePicker();
    if (minTimeStateValue !== minTimeValue) {
      setminTimeStateValue(minTimeValue);
    }
  }, [props.minDateValue]);

  useEffect(() => {
    const maxTimeValue = getMaxDateTimeMobileTimePicker();
    if (maxTimeStateValue !== maxTimeValue) {
      setmaxTimeStateValue(maxTimeValue);
    }
  }, [props.maxDateValue]);

  let labelClassName = isNotNullAndUndefined(props.labelClassName)
    ? props.labelClassName
    : "";
  let rowClassName = isNotNullAndUndefined(props.rowClassName)
    ? `${props.rowClassName} row`
    : RAFDefaultFieldClassName.rowClassName;
  let inputFieldClassName = isNotNullAndUndefined(props.inputFieldClassName)
    ? props.inputFieldClassName
    : 'col-12';
  if (titleLocation === 'Right') {
    rowClassName = `${rowClassName} flex-nowrap gx-2`;
    labelClassName = 'col-auto order-last';
    inputFieldClassName = 'col';
  }
  else if (titleLocation === 'Left') {
    rowClassName = `${rowClassName} flex-nowrap gx-2`;
    labelClassName = 'col-3';
    inputFieldClassName = 'col';
  }
  else if (titleLocation === 'Bottom') {
    labelClassName = 'order-last';
  }

  //const convertDateTime = (inVal) => {
  //    let inputValue = inVal;
  //    let fieldFormat = { type: RAFDataType.Date, format: "DD/MM/YYYY hh:mm A" }
  //    if (isNotNullAndUndefined(fieldFormat) && !IsNullOrWhiteSpace(inputValue) && isNotNullAndUndefined(inputValue)) {
  //        if (isNotNullAndUndefined(fieldFormat.type)) {
  //            if (fieldFormat.type === RAFDataType.Date) {
  //                if (moment(inputValue, moment.ISO_8601, true).isValid()) {
  //                    inputValue = moment(new Date(inputValue + "Z")).format(fieldFormat.format);
  //                }
  //            }
  //        }
  //    }
  //    return inputValue;
  //}

  //const created = (args, inputValue) => {
  //    if (dateTimePicker) {
  //        const that = dateTimePicker;
  //        setTimeout(() => {
  //            if (isNotNullAndUndefined(inputValue)) {
  //                that.value = convertDateTime(inputValue);
  //            }
  //        }, 100)
  //    }
  //}

  const roundOfftimeValue = (date: Date, minutes: number = intervalValue) => {
    if (roundOff) {//rounded off to nearest 15 minutes, if this feature is not required, remove this line and round of in this form initial values
      return roundToNearestInterval(date, minutes);
    } else {
      return date;
    }
  };

  function removeFocus() {
    if (isNotNullAndUndefined(dateTimePickerObj)) {
      dateTimePickerObj.focusOut();
    }
  }

  const setInitialValue = (input) => {
    const value = isNotNullAndUndefined(input) ? input.value : null;
    if (isNotNullAndUndefined(value)) {
      const roundedValue = roundOfftimeValue(value, intervalValue);
      if (roundedValue !== value) {
        input.onChange(roundedValue);
      }
    }
  };

  const getMinDateTimeMobileTimePicker = () => {
    let newMinTime = null;
    if (isNotNullAndUndefined(props.minDateValue)) {
      const minDateValue = new Date(props.minDateValue);
      newMinTime = moment(minDateValue);
    }
    return newMinTime;
  };

  const getMaxDateTimeMobileTimePicker = () => {
    let newMaxTime = null;
    if (isNotNullAndUndefined(props.maxDateValue)) {
      const maxDateValue = new Date(props.maxDateValue);
      newMaxTime = moment(maxDateValue);
    }
    return newMaxTime;
  };

  const onChangeMUITimePicker = (e, input) => {
    if (isNotNullAndUndefined(input)) {
      let value = isNotNullAndUndefined(e) ? e['_d'] : null;
      const inputValue = input.value;
      if (isNullOrUndefined(value) && isNotNullAndUndefined(inputValue)) {
        const date = new Date(inputValue);
        date.setHours(0, 0, 0, 0);
        value = null;// date;
      }

      input.onChange(value);
      if (isNotNullAndUndefined(onChanged)) {
        onChanged(value);
      }
    };
  };

  const onChangeMobileTimePicker = (e, input) => {
    if (isNotNullAndUndefined(input)) {
      let value = e;
      const inputValue = input.value;
      if (isNullOrUndefined(value) && isNotNullAndUndefined(inputValue)) {
        const date = new Date(inputValue);
        date.setHours(0, 0, 0, 0);
        value = null;// date;
      }

      input.onChange(value);
      if (isNotNullAndUndefined(onChanged)) {
        onChanged(value);
      }
    };
  };

  const onChangeMobileDatePicker = (dateValue, input) => {
    if (isNotNullAndUndefined(input)) {
      input.onChange(dateValue);
      if (isNotNullAndUndefined(onChanged)) {
        onChanged(dateValue);
      }
    }
  };
  //MUI_mobile_time_picker end

  // const theme = createTheme({
  //   components: {
  //     MuiDialog: {
  //       styleOverrides: {
  //         root: {
  //           backgroundColor: 'purple',
  //           className: 'raf_time_picker_dialog'
  //         },
  //       },
  //     },
  //   },
  // });


  const setCleared = (input) => {
    input.onChange(null);
    if (isNotNullAndUndefined(onChanged)) {
      onChanged(null);
    }
  };

  const getSelectDateContent = () => {
    return (
      <Field
        name={field.toString()}
        {...(props.initialValue
          ? { initialValue: props.initialValue }
          //? { initialValue: props.initialValue }
          : {})}
        //validate={validate === true ? (required && isRequired) : null}
        {...(props.validators
          ? {
            validate:
              validate === true
                ? composeValidators(
                  required === true ? isRequired : null,
                  ...props.validators
                )
                : null,
          }
          : {
            validate:
              validate === true
                ? composeValidators(
                  required === true ? isRequired : null)
                : null,
          })}
        allowNull
        parse={(value) => (value === "" ? null : value)}
      >
        {({ input, meta }) => {
          setInitialValue(input);

          const muiDateTimePickerActionsBtns: PickersActionBarAction[] = showClearButton === false ? ["cancel", 'accept'] : ['clear', "cancel", 'accept'];

          return (
            <div>
              {(displayStyle === 'SplitStyle' || displayStyle === 'TimePicker' || displayStyle === 'DesktopTimePicker' || isNotNullAndUndefined(uiType)) ? (
                <div className="row g-2">
                  {(uiType === RAFUIType.DateOnly || uiType === RAFUIType.DateTime) && (
                    <div className={`${uiType === RAFUIType.DateOnly ? 'col-md-12' : 'col-6'}`}>
                      <RAFCustomResponsiveDatePicker
                        fieldName={field.toString()}
                        disabled={disabled}
                        inputvalue={input.value}
                        showClearButton={showClearButton}
                        width={width}
                        onChangeMobileDatePicker={(value) => onChangeMobileDatePicker(value, input)}
                      />
                    </div>
                  )}
                  {(uiType === RAFUIType.TimeOnly || uiType === RAFUIType.DateTime) && (
                    <div className={`${uiType === RAFUIType.TimeOnly ? 'col-md-12 ' : 'col-6 '}raf_time_picker_col`} id={`timePicker_${guid}_${field.toString()}`}>
                      <LocalizationProvider dateAdapter={AdapterMoment}
                        localeText={{
                          // fieldMeridiemPlaceholder: (params) =>
                          //   //'AM/PM'
                          //   //params.format,
                          //   '',
                          // fieldHoursPlaceholder: (params) =>
                          //   '',
                          // fieldMinutesPlaceholder: (params) =>
                          //   '',
                        }}
                      >
                        <DemoContainer components={['TimePicker', 'MobileTimePicker']}>
                          {/* <ThemeProvider theme={theme}> */}
                          {displayStyle === 'TimePicker' ?
                            <TimePicker
                              className="raf_time_picker"
                              value={getComponentValueInMoment(input.value)}
                              autoFocus={false}
                              onChange={(e) => {
                                onChangeMUITimePicker(e, input);
                              }}
                              slotProps={{
                                actionBar: {
                                  actions: muiDateTimePickerActionsBtns
                                },
                                field: { clearable: showClearButton === true ? true : false }
                              }}
                              {...((roundOff === true)
                                ? { minutesStep: intervalValue }
                                : {})}
                              ampm={true}
                              disabled={disabled}
                              {...(isNotNullAndUndefined(minTimeStateValue)
                                ? { minTime: minTimeStateValue }
                                : {})}
                              {...(isNotNullAndUndefined(maxTimeStateValue)
                                ? { maxTime: maxTimeStateValue }
                                : {})}
                            // PopoverProps={{
                            //   container: () => document.getElementById('my-container'),
                            // }}


                            //ampmInClock={true}                           
                            // {...((isNotNullAndUndefined(minTimeStateValue) || isNotNullAndUndefined(maxTimeStateValue))
                            //   ? { disableIgnoringDatePartForTimeValidation: true }
                            //   : {})}
                            />
                            :
                            displayStyle === 'DesktopTimePicker' ?
                              <DesktopTimePicker
                                className="raf_time_picker"
                                value={getComponentValueInMoment(input.value)}
                                autoFocus={false}
                                onChange={(e) => {
                                  onChangeMUITimePicker(e, input);
                                }}
                                slotProps={{
                                  actionBar: {
                                    actions: muiDateTimePickerActionsBtns
                                  },
                                  field: { clearable: showClearButton === true ? true : false }
                                }}
                                {...((roundOff === true)
                                  ? { minutesStep: intervalValue }
                                  : {})}
                                ampm={true}
                                disabled={disabled}

                                {...(isNotNullAndUndefined(minTimeStateValue)
                                  ? { minTime: minTimeStateValue }
                                  : {})}
                                {...(isNotNullAndUndefined(maxTimeStateValue)
                                  ? { maxTime: maxTimeStateValue }
                                  : {})}

                              // PopoverProps={{
                              //   container: () => document.getElementById('my-container'),
                              // }}


                              //ampmInClock={true}
                              />
                              :
                              <RAFCustomResponsiveTimePicker
                                fieldName={field.toString()}
                                inputvalue={input.value}
                                interval={interval}
                                roundOff={roundOff}
                                maxTimeStateValue={maxTimeStateValue}
                                minTimeStateValue={minTimeStateValue}
                                onChangeTimePicker={(value) => onChangeMobileTimePicker(value, input)}
                                showClearButton={showClearButton}
                                disabled={disabled}
                                timePickerampm={timePickerampm}
                              />
                          }
                          {/* </ThemeProvider> */}
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  )}
                </div>
              ) : (
                <DateTimePickerComponent
                  name={input.name}
                  ref={(r) => (dateTimePickerObj = r)}
                  id={`dtpc${field.toString()}`}
                  //value={input.value}
                  value={getUTCDateValue(input.value)}
                  //allowEdit={false}
                  strictMode
                  change={(e) => {
                    if (e.isInteracted) {
                      input.onChange(e.value);
                      removeFocus();
                      if (isNotNullAndUndefined(onChanged)) {
                        onChanged(e.value);
                      }
                    }
                  }}
                  //openOnFocus
                  //created={e => created(e, input.value)}
                  format={RAFDatePickerViewFormat.DATETIME}
                  width={width}
                  disabled={disabled}
                  readonly={disabled}
                  showClearButton={showClearButton}
                  placeholder={props.placeholder}
                  cssClass={
                    meta.error && meta.touched ? "inputFieldError" : null
                  }
                  fullScreenMode={true}
                  {...(isNotNullAndUndefined(props.minDateValue)
                    ? { min: props.minDateValue }
                    : {})}
                  {...(isNotNullAndUndefined(props.maxDateValue)
                    ? { max: props.maxDateValue }
                    : {})}
                />
              )}

              {props.hideRequiredMessage !== true ? (
                <RAFFieldError name={field.toString()} />
              ) : (
                ""
              )}
            </div>
          );
        }}
      </Field>
    );
  };

  return (
    <div
      className={
        isNotNullAndUndefined(props.formGroupClassName)
          ? props.formGroupClassName + " form-group"
          : "form-group"
      }
    >
      <div className={rowClassName} id={"rafdiv" + field.toString()}>
        {showLabel && showLabel === true && (
          <RAFFieldLabel
            field={field}
            label={label}
            required={required}
            labelClassName={labelClassName}
            description={description}
            descriptionAsLabel={descriptionAsLabel}
            rightSection={props.labelRightSection}
          ></RAFFieldLabel>
        )}
        <div className={inputFieldClassName}>
          {getSelectDateContent()}
        </div>
      </div>
    </div>
  );
};

export default RAFDateTimePicker;
