import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { PropsWithChildren } from "react";
import { Field } from "react-final-form";
import { isNotNullAndUndefined } from "../../RAFComponents/helpers/utils";
import "./InputStyle.scss";
import RAFFieldLabel from "./RAFFieldLabel";
import { composeValidators, RAFFieldError } from "./RAFForm";
import {
  isEmail,
  isRequired,
  RAFDefaultFieldClassName,
  RAFDefaultFieldProps,
  RAFFieldProps,
} from "./RFFUtils";

function RAFEmailTextBox<T>({
  field,
  label,
  width,
  description,
  descriptionAsLabel,
  created,
  onChanged,
  children,
  required = RAFDefaultFieldProps.required,
  showLabel = RAFDefaultFieldProps.showLabel,
  disabled = RAFDefaultFieldProps.disabled,
  showClearButton = RAFDefaultFieldProps.showClearButton,
  validate = RAFDefaultFieldProps.validate,
  ...props
}: PropsWithChildren<RAFFieldProps<T>>) {
  let labelClassName = isNotNullAndUndefined(props.labelClassName)
    ? " " + props.labelClassName
    : "";
  return (
    <div
      className={
        isNotNullAndUndefined(props.formGroupClassName)
          ? props.formGroupClassName + " form-group"
          : "form-group"
      }
    >
      <div className={RAFDefaultFieldClassName.rowClassName} id={"rafdiv" + field.toString()}>
        {showLabel && showLabel === true && (
          <RAFFieldLabel
            field={field}
            label={label}
            required={required}
            labelClassName={labelClassName}
            description={description}
            descriptionAsLabel={descriptionAsLabel}
          ></RAFFieldLabel>
        )}
        <div className="col-12">
          <Field
            name={field.toString()}
            {...(props.initialValue
              ? { initialValue: props.initialValue }
              : {})}
            //validate={validate === true ? (required && isRequired) : null}
            //validate={validate === true ? ((isNotNullAndUndefined(required) && required === true) ? composeValidators(isRequired, isEmail) : isEmail) : null}

            {...(props.validators
              ? {
                validate:
                  validate === true
                    ? composeValidators(
                      isEmail,
                      required === true ? isRequired : null,
                      ...props.validators
                    )
                    : isEmail,
              }
              : {
                validate:
                  validate === true
                    ? composeValidators(
                      isEmail,
                      required === true ? isRequired : null
                    )
                    : isEmail,
              })}
            allowNull
            parse={(value) => (value === "" ? null : value)}
          >
            {({ input, meta }) => (
              <div className="w-100">
                <TextBoxComponent
                  value={input.value}
                  name={input.name}
                  id={`ttx_${field.toString()}`}
                  change={(e) => {
                    input.onChange(e.value);
                    if (isNotNullAndUndefined(onChanged)) {
                      onChanged(e.value);
                    }
                  }}
                  type="email"
                  created={() =>
                    isNotNullAndUndefined(created) ? created(input.name) : null
                  }
                  width={width && width !== null ? width : "100%"}
                  showClearButton={showClearButton}
                  placeholder={props.placeholder}
                  disabled={disabled}
                  cssClass={
                    meta.error && meta.touched ? "inputFieldError" : null
                  }
                />

                {props.hideRequiredMessage !== true ? (
                  <RAFFieldError name={field.toString()} />
                ) : (
                  ""
                )}
              </div>
            )}
          </Field>
        </div>
      </div>
    </div>
  );
}

export default RAFEmailTextBox;
