import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { ListViewComponent } from "@syncfusion/ej2-react-lists";
import { Dialog } from "@syncfusion/ej2-react-popups";
import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import * as React from "react";
import {
  PropsWithChildren,
  useContext,
  useEffect,
  useState
} from "react";
import ReactDOM from "react-dom";
import * as ReactDOMClient from "react-dom/client";
import { Field, FormRenderProps } from "react-final-form";
import {
  closeAllSFPopups,
  getQueryAttribute,
  hideProgress,
  removeSessionStorage,
  showProgress,
  useOutsideClick
} from "../../RAFComponents/helpers/AppHelper";
import {
  Guid,
  IsNullOrWhiteSpace,
  isNotNullAndUndefined,
  isNullOrUndefined,
} from "../../RAFComponents/helpers/utils";
import { StorageKey } from "../../constants/Common/Constants";
import { QueryAttributeJM, ValueJson } from "../models/Common/QueryAttributeJM";
import "./InputStyle.scss";
import ManageChoiceList from "./ManageChoiceList";
import RAFFieldLabel from "./RAFFieldLabel";
import { RAFFieldError, composeValidators } from "./RAFForm";
import {
  RAFDefaultFieldClassName,
  RAFDefaultFieldProps,
  RAFDropdownFieldProps,
  RAFFormContext,
  isRequired,
} from "./RFFUtils";

const getDataFromChildren = (
  children?,
  addEmpty?: boolean,
  emptyString?: string,
  attributeJM?: QueryAttributeJM
) => {
  let retVal: ValueJson[] = [];
  if (isNotNullAndUndefined(children) && children.length > 0) {
    if (isNotNullAndUndefined(addEmpty) && addEmpty === true) {
      retVal.push({
        Id: 0,
        Name: null,
        DisplayName: emptyString || "None",
        ColorCode: "#B3B6B7",
      });
    }
    React.Children.forEach(children, (child, i) => {
      //retVal.push({ ...child["props"], label: (child["props"]["children"] || child["props"]["label"]) });
      retVal.push({
        Id: i + 1,
        Name: child["props"]["value"],
        DisplayName: child["props"]["children"] || child["props"]["label"],
        ColorCode: child["props"]["colorCode"],
        Category: child["props"]["category"],
      });
    });
  } else {
    if (isNotNullAndUndefined(addEmpty) && addEmpty === true) {
      retVal.push({
        Id: 0,
        Name: "",
        DisplayName: emptyString || "None",
        ColorCode: "transparent",
      });
    }
    if (isNotNullAndUndefined(attributeJM)) {
      if (isNotNullAndUndefined(attributeJM.ValueJson)) {
        retVal.push(...attributeJM.ValueJson);
      }
    }
  }
  return retVal;
};

//const RAFDropdown = ({ field, label, required, children }) => {

interface IProps {
  moduleName?: string;
  allowAdd?: boolean;
  emptyString?: string;
  queryAttribute?: QueryAttributeJM;
  disableItems?: any[];
  displayDropdownType?:
  | "customDropdownBtn"
  | "customDropdownBtnForMinCount"
  | "colorDropdown"
  | "Default";
}

function RAFDropdown<T>({
  field,
  label,
  width,
  onChanged,
  children,
  moduleName,
  disableItems,
  description,
  descriptionAsLabel,

  uitype = "default",
  allowAdd = true,
  textField = "DisplayName",
  valueField = "Name",

  required = RAFDefaultFieldProps.required,
  showLabel = RAFDefaultFieldProps.showLabel,
  disabled = RAFDefaultFieldProps.disabled,
  showClearButton = RAFDefaultFieldProps.showClearButton,
  validate = RAFDefaultFieldProps.validate,
  ...props
}: PropsWithChildren<RAFDropdownFieldProps<T> & IProps>) {

  let fields;

  if (props.enableGroupByFormat) {
    fields = {
      groupBy: "Category",
      text: "DisplayName",
      value: "Name",
      itemCreated: (e) => {
        if (isNotNullAndUndefined(disableItems) && disableItems.length > 0) {
          disableItems.forEach((item) => {
            if (isNotNullAndUndefined(item) && item.value === e.curData.Name) {
              e.item.classList.add("e-disabled");
              e.item.classList.add("e-overlay");
            }
          });
        }
      },
    };
  }
  else {
    fields = {
      text: "DisplayName",
      value: "Name",
      itemCreated: (e) => {
        if (isNotNullAndUndefined(disableItems) && disableItems.length > 0) {
          disableItems.forEach((item) => {
            if (isNotNullAndUndefined(item) && item.value === e.curData.Name) {
              e.item.classList.add("e-disabled");
              e.item.classList.add("e-overlay");
            }
          });
        }
      },
    };
  }

  let labelClassName = isNotNullAndUndefined(props.labelClassName)
    ? " " + props.labelClassName
    : "";

  if (isNotNullAndUndefined(textField)) {
    fields.text = textField;
  }
  if (isNotNullAndUndefined(valueField)) {
    fields.value = valueField;
  }
  let dropdownBtnComponent: DropDownButtonComponent;
  let dropDownListComponent: DropDownListComponent;

  const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);
  //let choiceOptions = getDataFromChildren(React.Children.toArray(children));
  //let items = uitype === 'colorpicker' ? getDataFromChildren(null, true,'', attributeJM) : getDataFromChildren(React.Children.toArray(children));

  //const [items, setRafChoiceItems] = useState(choiceItems as RAFChoiceList[]);
  const [attributeJM, setAttributeJM] = useState(null);
  //const didMountRef = useRef(false)
  let indexVal: string = Guid.newGuid();

  let listViewComponent: ListViewComponent;

  const onItemClicked = (label, value, divId, colorCode) => {
    let customDropdownMenu = document.querySelectorAll("." + divId);
    if (isNotNullAndUndefined(customDropdownMenu)) {
      customDropdownMenu.forEach((item) => {
        item.classList.remove("e-popup-open");
        item.classList.add("e-popup-close");
      });
    }
    let customDropdownBtn = document.querySelector<HTMLElement>(
      ".customBtn_" + field.toString()
    );
    if (isNotNullAndUndefined(customDropdownBtn)) {
      if (isNullOrUndefined(props.mode)) {
        customDropdownBtn.style.background = colorCode;
      }
      //customDropdownBtn.style.color = '#fff';
      customDropdownBtn.style.paddingLeft = "15px";
      customDropdownBtn.style.paddingRight = "10px";
      customDropdownBtn.style.fontWeight = "400";
    }

    if (
      isNotNullAndUndefined(rafFormContextValue) &&
      isNotNullAndUndefined(rafFormContextValue.form) &&
      isNotNullAndUndefined(rafFormContextValue.form.mutators)
    ) {
      /*value === '' ?
                      rafFormContextValue.form.mutators.setValue(field.toString(), null) :
                      rafFormContextValue.form.mutators.setValue(field.toString(), value);*/
      label === ""
        ? rafFormContextValue.form.mutators.setValue(field.toString(), null)
        : rafFormContextValue.form.mutators.setValue(field.toString(), label);
    }

    if (isNotNullAndUndefined(onChanged)) {
      onChanged(label !== null ? label : null, value !== null ? value : null);
    }

    if (isNotNullAndUndefined(dropdownBtnComponent)) {
      //dropdownBtnComponent.content = label;
      if (props.mode === "iconView") {
        dropdownBtnComponent.element.innerHTML = `<div class="d-flex align-items-center justify-content-start"><i class=${props.iconName} style="color: ${colorCode}"></i><span>${label}</span>
            </div><span class="e-btn-icon e-icons e-icon-right e-caret"></span>`;
      } else if (props.mode === "squareView") {
        dropdownBtnComponent.element.innerHTML = `<div class="d-flex align-items-center justify-content-start"><i class="fas fa-square" style="color: ${colorCode}"></i><span>${label}</span>
            </div><span class="e-btn-icon e-icons e-icon-right e-caret"></span>`;
      } else {
        dropdownBtnComponent.element.innerHTML = `<div class="d-flex align-items-center justify-content-start"><i class="fas fa-circle" style="color: ${colorCode}"></i><span>${label}</span>
            </div><span class="e-btn-icon e-icons e-icon-right e-caret"></span>`;
      }
    }
  };

  function dialogClose(): void {
    const e1 = document.getElementById(
      "createDropdownDialog" + field.toString()
    );
    if (isNotNullAndUndefined(e1)) {
      e1.parentElement.remove();
    }
    document.body.classList.add("overflow-hidden");
  }

  function dialogOpen(): void {
    //console.log('dialogOpen');
    let choiceListContentDiv = document.getElementById(
      `divManageChoiceListContent${field.toString()}`
    );
    const root = ReactDOMClient.createRoot(choiceListContentDiv);
    root.render(
      <ManageChoiceList
        allowAdd
        field={field.toString()}
        onSave={() => refreshDropdown()}
        onClose={() => dialogClose()}
        attributeJM={attributeJM}
      //moduleName={moduleName}
      ></ManageChoiceList>
    );
  }

  //function Wrapped2<P = {}>({ as2: Component = 'a', ...props1 }: WrappedProps2<P>) {
  //    return (
  //        <Component {...props1} />
  //    );
  //}

  /*const createDialogContent = () => {
         
  
          return <ManageChoiceList allowAdd field={field.toString()} onSave={() => refreshDropdown()} onClose={() => dialogClose()} attributeJM={attributeJM} moduleName={moduleName}></ManageChoiceList>
  
          //if (isNotNullAndUndefined(createform)) {
          //    return (
          //        <>
  
          //            <ManageChoiceList allowAdd field={field.toString()} choiceListItems={choiceOptions} onSave={() => dialogClose()} onClose={() => dialogClose()}></ManageChoiceList>
          //        </>
          //    );
          //}
          //else {
          //    return (
          //        <div className="dialogContent">
          //            <span className="dialogText">Greetings Nancy! When will you share me the source files of the project?</span>
          //        </div>
          //    );
          //}
      }*/

  const createDynamicDialog = () => {
    //console.log('createDynamicDialog.content', content);
    let dialogObj: Dialog = new Dialog({
      //width: '335px',
      header: "Manage : " + field.toString(),
      showCloseIcon: true,
      //visible:,
      cssClass: "centerDialog-sm choice-list-dialog",
      //overlayClick:,
      isModal: true,
      target: "body",
      closeOnEscape: false,
      //content: `<ManageChoiceList allowAdd=${true} field=${field.toString()} choiceListItems=${choiceOptions}></ManageChoiceList>`,//' <div className="dialogContent">aaa</div>',//createDialogContent(objComp) as any,
      //content: createDialogContent as any,//`<Wrapped<LinkProps> as=${createform} to="/foo">Something</Wrapped>`,//' <div className="dialogContent">aaa</div>',//createDialogContent(objComp) as any,
      content: `<div id="divManageChoiceListContent${field.toString()}"></div>`,
      animationSettings: { effect: "None" },
      //buttons: [{
      //    click: dlgButtonClick,
      //    buttonModel: { content: 'OK', cssClass: 'e-flat', isPrimary: true }
      //}],
      open: dialogOpen,
      close: dialogClose,
    });
    const ele = document.createElement("div");
    ele.setAttribute("id", "createDropdownDialog" + field.toString());
    if (
      document.getElementById("createDropdownDialog" + field.toString()) ===
      null
    ) {
      const newtarget = document.getElementById("rafdiv" + field.toString());
      newtarget.appendChild(ele);
      dialogObj.appendTo("#createDropdownDialog" + field.toString());
    } else {
      dialogObj.appendTo("#createDropdownDialog" + field.toString());
    }

    //ReactDOM.createPortal(createDialogContent as any, dialogObj.element);
    ReactDOM.render(
      <ManageChoiceList
        allowAdd
        field={field.toString()}
        onSave={() => refreshDropdown()}
        onClose={() => dialogClose()}
        attributeJM={attributeJM}
      //moduleName={moduleName}
      ></ManageChoiceList>,
      document.getElementById(`divManageChoiceListContent${field.toString()}`)
    );
  };

  const CreateModalToggle = () => {
    //let dropdownTypeInitialvalues = createInstance(AttributeChoiceList);
    //dropdownTypeInitialvalues['ChoiceList'] = choiceOptions;
    createDynamicDialog();
  };

  const refreshDropdown = () => {
    removeSessionStorage(StorageKey.allAttribs_modulename + moduleName, true);
    removeSessionStorage(StorageKey.viewAttribs_modulename + moduleName, true);
    if (isNullOrUndefined(props.queryAttribute)) {
      let progressDiv = showProgress("#rafdiv" + field.toString());
      getQueryAttribute(moduleName, field.toString())
        .then((queryAttribute) => {
          hideProgress(progressDiv);
          setAttributeJM(queryAttribute);
        })
        .catch((error) => error);
    }
    dialogClose();
  };

  useEffect(() => {
    const fetchDropdowItems = () => {
      if (isNullOrUndefined(props.queryAttribute)) {
        getQueryAttribute(moduleName, field.toString())
          .then((queryAttribute) => {
            setAttributeJM(queryAttribute);
          })
          .catch((error) => error);
      } else {
        setAttributeJM(props.queryAttribute);
      }
    };

    //if (didMountRef.current) {
    fetchDropdowItems();
    //}
    //else didMountRef.current = true
  }, [props.queryAttribute]);

  //const items = getDataFromChildren(null, true, '', attributeJM);
  const items =
    uitype === "colorpicker"
      ? getDataFromChildren(
        null,
        required ? false : true,
        props.emptyString,
        attributeJM
      )
      : getDataFromChildren(
        React.Children.toArray(children),
        false,
        "",
        attributeJM
      );
  setTimeout(() => {
    setClickEvents(items);
    if (isNotNullAndUndefined(listViewComponent)) {
      listViewComponent.dataSource = items as { [key: string]: Object; }[];
    }
  }, 100);


  const setClickEvents = (items1) => {
    const btnManageChoice: HTMLElement = document.getElementById(
      `btnManageChoice_${field.toString()}_${indexVal}`
    );
    if (isNotNullAndUndefined(btnManageChoice)) {
      btnManageChoice.onclick = () => {
        CreateModalToggle();
      };
    }

    if (isNotNullAndUndefined(items1) && items1.length > 0) {
      for (var i = 0; i < items1.length; i++) {
        const objItem = items1[i];
        const objElement: HTMLElement = document.getElementById(
          `dropdown_item_${objItem.Name}_${field.toString()}_${indexVal}`
        );
        if (isNotNullAndUndefined(objElement)) {
          objElement.onclick = () => {
            onItemClicked(
              objItem.DisplayName,
              objItem.Name,
              "customBtn_" + field.toString().replace(".", "_"),
              objItem.ColorCode
            );
          };
        }
      }
    }
  };

  /*const getDisplayNamebyName = (name) => {
          let displayName = '';
          let selectedItem = items && items.find(x => x.Name === name);
          displayName = selectedItem && selectedItem.DisplayName;
          return displayName;
      }*/

  const setValueDelay = (inputValue) => {
    if (isNotNullAndUndefined(inputValue) && !IsNullOrWhiteSpace(inputValue)) {
      setTimeout(() => {
        if (dropDownListComponent) {
          dropDownListComponent.value = inputValue;
        }
      }, 100);
    }
  };
  // const dropdownClick = (e) => {
  //     //console.log("e",e);
  // };
  const dropdownClick = (btnClass, containerId) => {
    let btnDiv = document.querySelector("." + btnClass);
    let containerDiv = document.getElementById(containerId);
    let btnDivWidth = btnDiv.clientWidth;
    containerDiv.style.width = btnDivWidth + "px";
  };
  const handleClickOutside = () => {
    // this.dropdownBtnComponent.popup.close();
    // console.log("dropdwon", this.dropdownBtnComponent, this.dropdownBtnComponent.popup)
    const refCurrent = ref.current as HTMLElement;

    if (refCurrent.classList.contains("e-popup")) return;
    if (isNotNullAndUndefined(refCurrent.closest("e-popup-open"))) return;
    closeAllSFPopups();
    //     setCount(0);
  };
  const ref = useOutsideClick(handleClickOutside);
  //console.log("dropdown")

  const nodeTemplate = (data) => {
    let mode = props.mode;
    if (isNotNullAndUndefined(data)) {
      const item = data;
      let backgroundColor;
      let color;
      let iconColor;
      let iconName;
      if (isNotNullAndUndefined(mode)) {
        backgroundColor = "transparent";
        color = "block";
        iconColor = item.ColorCode;
        if (mode === "squareView") {
          iconName = "fas fa-square";
        } else if (mode === "iconView") {
          iconName = props.iconName;
        } else if (mode === "discView") {
          iconName = "fas fa-circle";
        }
      } else {
        backgroundColor = item.ColorCode;
        color = "white";
        iconColor = item.ColorCode;
      }

      return (
        <div
          id={`dropdown_item_${item.Name}_${field.toString()}_${indexVal}`}
          className="dropdown_item px-3"
          style={{
            background: backgroundColor,
            color:
              isNotNullAndUndefined(color) &&
                item.ColorCode !== "transparent" &&
                item.ColorCode !== ""
                ? color
                : "black",
          }}
        //onClick={() => this.onItemClicked(item.DisplayName, item.Name, 'customBtn_' + field.toString(), item.ColorCode)}
        >
          {isNotNullAndUndefined(mode) ? (
            <i
              className={`${iconName} me-2`}
              style={{
                color: iconColor,
              }}
            ></i>
          ) : (
            ""
          )}
          <span className="text-truncate">{item.DisplayName}</span>
          {/* {input.value ===
                            item.DisplayName ? (
                            <span className="fas fa-check"></span>
                        ) : null} */}
        </div>
      );
    }
  };
  const onNodeSelect = (e) => {
    let divId = "customBtn_" + field.toString();
    let customDropdownMenu = document.querySelectorAll("." + divId);
    if (isNotNullAndUndefined(customDropdownMenu)) {
      customDropdownMenu.forEach((item) => {
        item.classList.remove("e-popup-open");
        item.classList.add("e-popup-close");
      });
    }
    let customDropdownBtn = document.querySelector<HTMLElement>(
      `.customBtn_${field.toString()}`
    );
    if (isNotNullAndUndefined(customDropdownBtn)) {
      if (isNullOrUndefined(props.mode)) {
        customDropdownBtn.style.background = e.colorCode;
      }
      //customDropdownBtn.style.color = '#fff';
      customDropdownBtn.style.paddingLeft = "15px";
      customDropdownBtn.style.paddingRight = "10px";
      customDropdownBtn.style.fontWeight = "400";
    }

    if (
      isNotNullAndUndefined(rafFormContextValue) &&
      isNotNullAndUndefined(rafFormContextValue.form) &&
      isNotNullAndUndefined(rafFormContextValue.form.mutators)
    ) {
      // if (clearSelectionOnClick === true) {
      //     let currentfiledValue = this.rafFormContextValue.values[this.field];
      //     if (currentfiledValue === e.text) {
      //         e.text = null;
      //         e.text = null;
      //     }
      // }
      /*value === '' ?
                          rafFormContextValue.form.mutators.setValue(field.toString(), null) :
                          rafFormContextValue.form.mutators.setValue(field.toString(), value);*/
      e.text === ""
        ? rafFormContextValue.form.mutators.setValue(field, null)
        : rafFormContextValue.form.mutators.setValue(field, e.text);
    }

    if (isNotNullAndUndefined(onChanged)) {
      onChanged(
        e.text !== null ? e.text : null,
        e.text !== null ? e.text : null
      );
    }

    if (isNotNullAndUndefined(dropdownBtnComponent)) {
      //dropdownBtnComponent.content = label;
      if (props.mode === "iconView") {
        dropdownBtnComponent.element.innerHTML = `<div><i class=${props.iconName} style="color: ${e.colorCode}"></i><span>${e.text}</span>
            </div><span class="e-btn-icon e-icons e-icon-right e-caret"></span>`;
      } else if (props.mode === "squareView") {
        dropdownBtnComponent.element.innerHTML = `<div><i class="fas fa-square" style="color: ${e.colorCode}"></i><span>${e.text}</span>
            </div><span class="e-btn-icon e-icons e-icon-right e-caret"></span>`;
      } else {
        dropdownBtnComponent.element.innerHTML = `<div><i class="fas fa-circle" style="color: ${e.colorCode}"></i><span>${e.text}</span>
            </div><span class="e-btn-icon e-icons e-icon-right e-caret"></span>`;
      }
    }
  };

  return (
    <div
      className={
        isNotNullAndUndefined(props.formGroupClassName)
          ? props.formGroupClassName + " form-group"
          : "form-group"
      }
    >
      <div className={RAFDefaultFieldClassName.rowClassName} id={"rafdiv" + field.toString()}>
        {showLabel && showLabel === true && (
          <RAFFieldLabel
            field={field}
            label={label}
            required={required}
            labelClassName={labelClassName}
            description={description}
            descriptionAsLabel={descriptionAsLabel}
          ></RAFFieldLabel>
        )}
        <div className="col-12">
          <Field
            name={field.toString()}
            {...(props.initialValue
              ? { initialValue: props.initialValue }
              : {})}
            //validate={validate === true ? (required && isRequired) : null}
            {...(props.validators
              ? {
                validate:
                  validate === true
                    ? composeValidators(
                      required === true ? isRequired : null,
                      ...props.validators
                    )
                    : null,
              }
              : {
                validate:
                  validate === true
                    ? composeValidators(required === true ? isRequired : null)
                    : null,
              })}
            allowNull
            parse={(value) => (value === "" ? null : value)}
          >
            {({ input, meta }) => {
              setValueDelay(input.value);
              return (
                <div>
                  {uitype === "colorpicker" ? (
                    isNotNullAndUndefined(props.mode) ? (
                      <div
                        className="row g-2 align-items-center"
                        key={Math.random()}
                      >
                        {items && items.length >= 5 && (
                          <div className="col">
                            {/* <div
                                                            //ref={ref}
                                                            className="customDropdownMenu"
                                                            id={
                                                                "drodownBtnComponent" +
                                                                field.toString() +
                                                                "_" +
                                                                indexVal
                                                            }
                                                        >
                                                            {items.map((item, index1) => {
                                                                return (
                                                                    <div
                                                                        key={`${item.Name}${index1}`}
                                                                        id={`dropdown_item_${item.Name
                                                                            }_${field.toString()}_${indexVal}`}
                                                                        className={"dropdown_item colorIcon"}
                                                                        onClick={() =>
                                                                            onItemClicked(
                                                                                item.DisplayName,
                                                                                item.Name,
                                                                                "customBtn_" + field.toString(),
                                                                                item.ColorCode
                                                                            )
                                                                        }
                                                                    >
                                                                        {props.mode === "squareView" ? (
                                                                            <i
                                                                                className="fas fa-square"
                                                                                style={{ color: item.ColorCode }}
                                                                            ></i>
                                                                        ) : props.mode === "iconView" ? (
                                                                            <i
                                                                                className={props.iconName}
                                                                                style={{ color: item.ColorCode }}
                                                                            ></i>
                                                                        ) : (
                                                                            <i
                                                                                className="fas fa-circle"
                                                                                style={{ color: item.ColorCode }}
                                                                            ></i>
                                                                        )}
                                                                        <span className="w-100">
                                                                            {item.DisplayName}
                                                                        </span>
                                                                        {input.value === item.DisplayName ? (
                                                                            <span className="fas fa-check"></span>
                                                                        ) : null}
                                                                    </div>
                                                                );
                                                            })}
                                                        </div> */}
                            <ListViewComponent
                              id={
                                "drodownBtnComponent" +
                                field.toString() +
                                "_" +
                                indexVal
                              }
                              ref={(g) => (listViewComponent = g)}
                              template={nodeTemplate}
                              // dataSource={items}
                              fields={{
                                text: "DisplayName",
                                tooltip: "DisplayName",
                                id: "UID",
                              }}
                              select={onNodeSelect}
                            />
                            <DropDownButtonComponent
                              ref={(d) => (dropdownBtnComponent = d)}
                              id={`ddb_${field.toString()}`}
                              onClick={() =>
                                dropdownClick(
                                  "customBtn_" +
                                  field.toString().replace(".", "_"),
                                  "drodownBtnComponent" +
                                  field.toString().replace(".", "_") +
                                  "_" +
                                  indexVal
                                )
                              }
                              target={
                                "#drodownBtnComponent" +
                                field.toString() +
                                "_" +
                                indexVal
                              }
                              style={{ fontWeight: 400 }}
                              cssClass={`custom-button-lg customDropdownBtn customBtn_
                                                                ${field.toString()}`}
                            >
                              {IsNullOrWhiteSpace(input.value) ? (
                                isNotNullAndUndefined(props.emptyString) ? (
                                  props.emptyString
                                ) : (
                                  "None"
                                )
                              ) : (
                                <div>
                                  {props.mode === "squareView" ? (
                                    <i
                                      className="fas fa-square"
                                      style={{
                                        color:
                                          // && items.find(
                                          //     (x) => x.DisplayName === input.value)
                                          items?.find(
                                            (x) => x.DisplayName === input.value
                                          )?.ColorCode,
                                      }}
                                    ></i>
                                  ) : props.mode === "iconView" ? (
                                    <i
                                      className={props.iconName}
                                      style={{
                                        color: items?.find(
                                          (x) => x.DisplayName === input.value
                                        )?.ColorCode,
                                        // color:items &&items.find((x) => x.DisplayName === input.value) &&items.find((x) => x.DisplayName === input.value).ColorCode,
                                      }}
                                    ></i>
                                  ) : (
                                    <i
                                      className="fas fa-circle"
                                      style={{
                                        color: items?.find(
                                          (x) => x.DisplayName === input.value
                                        )?.ColorCode,
                                        // color:items &&items.find((x) => x.DisplayName === input.value) &&items.find((x) => x.DisplayName === input.value).ColorCode,
                                      }}
                                    ></i>
                                  )}
                                  <span>{input.value}</span>
                                </div>
                              )}
                            </DropDownButtonComponent>
                            {/*<div className="customDropdownMenu" id={"drodownBtnComponent" + field.toString()}>
                                                {items.map(item => {
                                                    return <div key={item.Name} id={`dropdown_item_${item.Name}`} className="dropdown_item" style={{ background: item.ColorCode, color: isNotNullAndUndefined(item.ColorCode) ? "white" : "black" }} onClick={() => onItemClicked(item.DisplayName, item.Name, 'customBtn_' + field.toString(), item.ColorCode)}><span>{item.DisplayName}</span>{input.value === item.Name ? <span className="e-icon e-selection"></span> : null}</div>
                                                })}
                                            </div><DropDownButtonComponent ref={d => dropdownBtnComponent = d} target={"#drodownBtnComponent" + field.toString()} style={{ color: isNotNullAndUndefined(items && items.find(x => x.Name === input.value) && items.find(x => x.Name === input.value).ColorCode) ? "white" : "black", background: items && items.find(x => x.Name === input.value) && items.find(x => x.Name === input.value).ColorCode, fontWeight: 400 }} cssClass={'customDropdownBtn customBtn_' + field.toString()}>{IsNullOrWhiteSpace(input.value) ? '\u00a0' : getDisplayNamebyName(input.value)}</DropDownButtonComponent>*/}
                          </div>
                        )}
                        {items && (items.length <= 4 || props.displayDropdownType === 'customDropdownBtn') && (
                          <div className="col">
                            <div className="row g-2">
                              {items.map((item, index1) => {
                                return (
                                  <div
                                    key={`${item.Name}${index1}`}
                                    className="col"
                                  >
                                    <div
                                      id={`dropdown_item_${item.Name
                                        }_${field.toString()}_${indexVal}`}
                                      className={
                                        input.value === item.DisplayName
                                          ? "custom-dropdown-btn active"
                                          : "col custom-dropdown-btn"
                                      }
                                      onClick={() =>
                                        onItemClicked(
                                          item.DisplayName,
                                          item.Name,
                                          "customBtn_" + field.toString(),
                                          item.ColorCode
                                        )
                                      }
                                    >
                                      {props.mode === "squareView" ? (
                                        <i
                                          className="fas fa-square"
                                          style={{ color: item.ColorCode }}
                                        ></i>
                                      ) : props.mode === "iconView" ? (
                                        <i
                                          className={props.iconName}
                                          style={{ color: item.ColorCode }}
                                        ></i>
                                      ) : (
                                        <i
                                          className="fas fa-circle"
                                          style={{ color: item.ColorCode }}
                                        ></i>
                                      )}
                                      <span className="w-100 px-2">
                                        {item.DisplayName}
                                      </span>
                                      {input.value === item.DisplayName ? (
                                        <span className="fas fa-check"></span>
                                      ) : null}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                        {allowAdd && allowAdd === true && (
                          <div className="col-auto">
                            <ButtonComponent
                              id={`btnManageChoice_${field.toString()}_${indexVal}`}
                              type="button"
                              cssClass="e-flat icon-only default filterIcon"
                              iconCss="fas fa-plus "
                            //onClick={() => CreateModalToggle()}
                            ></ButtonComponent>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div
                        className="row g-2 align-items-center"
                        key={Math.random()}
                      >
                        {items && items.length >= 5 && (
                          <div className="col">
                            <ListViewComponent
                              id={
                                "drodownBtnComponent_" +
                                field.toString().replace(".", "_") +
                                "_" +
                                indexVal
                              }
                              ref={(g) => (listViewComponent = g)}
                              template={nodeTemplate}
                              fields={{
                                text: "DisplayName",
                                tooltip: "DisplayName",
                                id: "UID",
                              }}
                              select={onNodeSelect}
                            />
                            <DropDownButtonComponent
                              id={`ddb_${field.toString()}`}
                              onClick={() =>
                                dropdownClick(
                                  "customBtn_" +
                                  field.toString().replace(".", "_"),
                                  "drodownBtnComponent_" +
                                  field.toString().replace(".", "_") +
                                  "_" +
                                  indexVal
                                )
                              }
                              target={
                                "#drodownBtnComponent_" +
                                field.toString().replace(".", "_") +
                                "_" +
                                indexVal
                              }
                              style={{
                                color:
                                  isNotNullAndUndefined(
                                    // items &&items.find((x) => x.DisplayName === input.value)&&items.find((x) => x.DisplayName === input.value).ColorCode
                                    items?.find(
                                      (x) => x.DisplayName === input.value
                                    )?.ColorCode
                                  ) &&
                                    items.find(
                                      (x) => x.DisplayName === input.value
                                    ).ColorCode !== "transparent" &&
                                    items.find(
                                      (x) => x.DisplayName === input.value
                                    ).ColorCode !== ""
                                    ? "white"
                                    : "black",
                                background: items?.find(
                                  (x) => x.DisplayName === input.value
                                )?.ColorCode,
                                // background:items &&items.find((x) => x.DisplayName === input.value)&&items.find((x) => x.DisplayName === input.value).ColorCode,
                                fontWeight: 400,
                              }}
                              cssClass={
                                "customDropdownBtn custom-button-lg customBtn_" +
                                field.toString().replace(".", "_")
                              }
                            >
                              {IsNullOrWhiteSpace(input.value)
                                ? isNotNullAndUndefined(props.emptyString)
                                  ? props.emptyString
                                  : isNotNullAndUndefined(required) && required
                                    ? null
                                    : "None"
                                : input.value}
                            </DropDownButtonComponent>
                            {/* <div
                                                            // ref={ref}
                                                            className="customDropdownMenu"
                                                            id={
                                                                "drodownBtnComponent_" +
                                                                field.toString().replace(".", "_") +
                                                                "_" +
                                                                indexVal
                                                            }
                                                        >
                                                            {items.map((item, index1) => {
                                                                return (
                                                                    <div
                                                                        key={`${item.Name}${index1}`}
                                                                        id={`dropdown_item_${item.Name
                                                                            }_${field.toString()}_${indexVal}`}
                                                                        className="dropdown_item"
                                                                        style={{
                                                                            background: item.ColorCode,
                                                                            color:
                                                                                isNotNullAndUndefined(item.ColorCode) &&
                                                                                    item.ColorCode !== "transparent" &&
                                                                                    item.ColorCode !== ""
                                                                                    ? "white"
                                                                                    : "black",
                                                                        }}
                                                                        onClick={() =>
                                                                            onItemClicked(
                                                                                item.DisplayName,
                                                                                item.Name,
                                                                                "customBtn_" +
                                                                                field.toString().replace(".", "_"),
                                                                                item.ColorCode
                                                                            )
                                                                        }
                                                                    >
                                                                        <span>{item.DisplayName}</span>
                                                                        {input.value === item.DisplayName ? (
                                                                            <span className="fas fa-check"></span>
                                                                        ) : null}
                                                                    </div>
                                                                );
                                                            })}
                                                        </div> */}

                            {/*<div className="customDropdownMenu" id={"drodownBtnComponent" + field.toString()}>
                                                {items.map(item => {
                                                    return <div key={item.Name} id={`dropdown_item_${item.Name}`} className="dropdown_item" style={{ background: item.ColorCode, color: isNotNullAndUndefined(item.ColorCode) ? "white" : "black" }} onClick={() => onItemClicked(item.DisplayName, item.Name, 'customBtn_' + field.toString(), item.ColorCode)}><span>{item.DisplayName}</span>{input.value === item.Name ? <span className="e-icon e-selection"></span> : null}</div>
                                                })}
                                            </div><DropDownButtonComponent ref={d => dropdownBtnComponent = d} target={"#drodownBtnComponent" + field.toString()} style={{ color: isNotNullAndUndefined(items && items.find(x => x.Name === input.value) && items.find(x => x.Name === input.value).ColorCode) ? "white" : "black", background: items && items.find(x => x.Name === input.value) && items.find(x => x.Name === input.value).ColorCode, fontWeight: 400 }} cssClass={'customDropdownBtn customBtn_' + field.toString()}>{IsNullOrWhiteSpace(input.value) ? '\u00a0' : getDisplayNamebyName(input.value)}</DropDownButtonComponent>*/}
                          </div>
                        )}
                        {items && items.length <= 4 && (
                          <div className="col">
                            <div className="row g-2">
                              {items.map((item, index1) => {
                                return (
                                  <div
                                    className="col"
                                    key={`${item.Name}${index1}`}
                                  >
                                    <div
                                      id={`dropdown_item_${item.Name
                                        }_${field.toString()}_${indexVal}`}
                                      className={
                                        input.value === item.DisplayName
                                          ? "custom-dropdown-btn active"
                                          : "col custom-dropdown-btn"
                                      }
                                      style={{
                                        background: item.ColorCode,
                                        color:
                                          isNotNullAndUndefined(
                                            item.ColorCode
                                          ) &&
                                            item.ColorCode !== "transparent" &&
                                            item.ColorCode !== ""
                                            ? "white"
                                            : "black",
                                      }}
                                      onClick={() =>
                                        onItemClicked(
                                          item.DisplayName,
                                          item.Name,
                                          "customBtn_" + field.toString(),
                                          item.ColorCode
                                        )
                                      }
                                    >
                                      <span className="w-100 px-2">
                                        {item.DisplayName}
                                      </span>
                                      {input.value === item.DisplayName ? (
                                        <span className="fas fa-check"></span>
                                      ) : null}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                        {allowAdd && allowAdd === true && (
                          <div className="col-auto">
                            <ButtonComponent
                              id={`btnManageChoice_${field.toString()}_${indexVal}`}
                              type="button"
                              cssClass="e-flat icon-only default filterIcon"
                              iconCss="fas fa-plus "
                            //onClick={() => CreateModalToggle()}
                            ></ButtonComponent>
                          </div>
                        )}
                      </div>
                    )
                  ) : (
                    <DropDownListComponent
                      id={`ddl_${field.toString()}`}
                      name={input.name}
                      value={input.value}
                      ref={(d) => (dropDownListComponent = d)}
                      change={(e) => {
                        if (e.isInteracted) {
                          input.onChange(
                            e.itemData !== null
                              ? e.itemData[fields.value]
                              : null
                          );
                          if (isNotNullAndUndefined(onChanged)) {
                            onChanged(
                              e.itemData !== null
                                ? e.itemData[fields.text]
                                : null,
                              e.itemData !== null
                                ? e.itemData[fields.value]
                                : null
                            );
                          }
                        }
                      }}
                      dataSource={items as any}
                      fields={fields}
                      //delayUpdate
                      allowFiltering={props.allowFiltering}
                      width={width}
                      readonly={disabled}
                      filterType="Contains"
                      //itemTemplate={isColorOption ? itemTemplate : null} valueTemplate={isColorOption ? valueTemplate : null}
                      cssClass={`${props.enableGroupByFormat ? 'unsetHeight ' : ''}
                        ${meta.error && meta.touched ? "inputFieldError" : null}${isNotNullAndUndefined(props.componentCssClass) ? ` ${props.componentCssClass}` : ''}
                      `}
                      placeholder={props.placeholder}
                      {...(showClearButton === true
                        ? { showClearButton: showClearButton }
                        : {})}
                    //{...props}
                    />
                  )}

                  {props.hideRequiredMessage !== true ? (
                    <RAFFieldError name={field.toString()} />
                  ) : (
                    ""
                  )}
                </div>
              );
            }}
          </Field>
        </div>
      </div>
    </div>
  );
}

export default RAFDropdown;
