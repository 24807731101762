import { ToastComponent } from "@syncfusion/ej2-react-notifications";
import { useContext, useEffect, useRef, useState } from "react";
import { useFormState } from 'react-final-form';
import { BrowserIsDevice } from "../../constants/Common/Constants";
import { RAFAttributesContext } from "../Providers/RAFAttributeRelatedListProvider";
import { isNotEmptyArray, isNotNullAndUndefined } from "../helpers/utils";

class ErrorKeyRow {
  FieldName: string;
  Error: string;
}

const convertToFieldNameErrorArray = (obj) => {
  let result: ErrorKeyRow[] = [];
  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === 'object' && value !== null) {
      result = result.concat(convertToFieldNameErrorArray(value));
    } else if (typeof value === 'string' && value !== null) {
      result.push({ FieldName: key, Error: value });
    }
  }
  return result;
};

const RAFFormErrorMessage = () => {

  const attributesContext = useContext(RAFAttributesContext);
  const queryAttributes = isNotNullAndUndefined(attributesContext) ? attributesContext.queryAttributes : null;

  let toastObj = useRef<ToastComponent>(null);
  const tempPosition = !BrowserIsDevice ? { X: 'Center', Y: 'Top' } : { X: 'Center', Y: 'Top' };

  const [errorKeysArray, setErrorKeysArray] = useState<ErrorKeyRow[]>([]);
  //const [errorKeys, setErrorKeys] = useState([]);
  const [isToastShown, setIsToastShown] = useState(false);

  const handleErrors = ({ submitFailed, errors }) => {
    if (submitFailed && errors) {
      const errorKeysArray = convertToFieldNameErrorArray(errors);
      setErrorKeysArray(errorKeysArray);
      //const keys = Object.keys(errors);
      //setErrorKeys(keys);
    } else {
      setErrorKeysArray([]);
      //setErrorKeys([]);
    }
  };

  const { submitFailed, errors } = useFormState({ subscription: { submitFailed: true, errors: true } });
  useEffect(() => {
    if (submitFailed === true && errors) {
      handleErrors({ submitFailed, errors });
    }
  }, [submitFailed, errors]);

  useEffect(() => {

    if (isNotEmptyArray(errorKeysArray)) {
      // Call function if errorKeys is greater than 0
      if (toastObj && toastObj.current && !isToastShown) {
        setIsToastShown(true);
        toastObj.current.show();
      }
    }
    else {
      if (toastObj && toastObj.current && isToastShown) {
        setIsToastShown(false);
        toastObj.current.hide();
      }
    }
    return () => {
      if (toastObj && toastObj.current && isToastShown) {
        setIsToastShown(false);
        toastObj.current.hide();
      }
    };
  }, [errorKeysArray, toastObj, isToastShown]);

  const templatedata = (errorKeysArray: ErrorKeyRow[]) => {
    return (
      <div className="d-flex">
        {/* <i className="fas fa-hand-point-right pe-2 pt-1"></i> */}
        <div className="row gx-0 gy-2">
          {/* <div className="col-12">
            <span className="header-text-sm-without-color">
              Please provide the values for required fields:
            </span>
          </div> */}
          {errorKeysArray.map((item) => {
            const attribute = isNotEmptyArray(queryAttributes) ? queryAttributes.find(x => x.FieldName === item.FieldName) : null;
            const displayKey = isNotNullAndUndefined(attribute) ? attribute.DisplayName : item.FieldName;
            return (
              <div className="col-12" key={item.FieldName}>
                <div className="d-flex align-items-center">
                  <i className="fas fa-hand-point-right pe-2 fa-sm"></i>
                  {/* <i className="fas fa-circle pe-2" style={{ fontSize: '8px' }}></i> */}
                  <span className="header-text-sm-light-without-color">
                    {
                      // `${[key]}`
                      `${[displayKey]} : ${item.Error}`
                    }
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };


  return (
    <div>
      <ToastComponent
        ref={toastObj}
        id='toast_template'
        position={tempPosition}
        showCloseButton
        target={'body'}
        template={templatedata.bind(this, errorKeysArray)}
        extendedTimeout={0} timeOut={120000}
        cssClass="e-toast-danger form-toast"
        // open={onOpenToast.bind(this)}
        // close={onToastClose.bind(this)}
        // beforeOpen={onToastBeforeOpen.bind(this)}
        //created={toastObjCreate.bind(this)}
        {...BrowserIsDevice ? { width: '100%' } : {}}
        style={{ maxWidth: '400px', width: '100vw', left: 'unset' }}
      >
      </ToastComponent>
    </div>
  );
};

export default RAFFormErrorMessage;