import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { Component } from "react";
import { FormRenderProps } from "react-final-form";
import { IsNullOrWhiteSpace, RAFFormComponentProps, getSaveRequest, isNotNullAndUndefined } from "../../RAFComponents/helpers/utils";
import { Constants, ContentType, RAFLayout } from "../../constants/Common/Constants";
import { showWarningToast } from "../Utility/RAFToastComponent";
import { hideProgress, showProgress } from "../helpers/AppHelper";
import { QueryAttributeJM, ValueJson } from "../models/Common/QueryAttributeJM";
import * as repositoryActions from '../store/actions/repositoryActions';
import "./InputStyle.scss";
import RAFChoiceColorOption from "./RAFChoiceColorOption";
import RAFForm from "./RAFForm";

interface IProps {
    field?: string;
    allowAdd?: boolean;
    onClose?: () => void;
    onSave?: () => void;
    attributeJM?: QueryAttributeJM;
}

class ManageChoiceList extends Component<IProps & RAFFormComponentProps> {

    public rafDropdownForm: FormRenderProps | null;

    onSubmit = (value) => {
        let attributeRow: QueryAttributeJM = { ...value };
        if (isNotNullAndUndefined(attributeRow) && isNotNullAndUndefined(attributeRow.AttributeUID) && isNotNullAndUndefined(attributeRow.ValueJson)) {
            let valuesJson: ValueJson[] = [];
            attributeRow.ValueJson.forEach((item, index) => {
                if (isNotNullAndUndefined(item.DisplayName) && !IsNullOrWhiteSpace(item.DisplayName.trim())) {
                    let valueJson = item;
                    let displayName = valueJson.DisplayName.trim();
                    //valueJson.Name = ConvertSystemName(displayName);
                    valueJson.Name = displayName;
                    valueJson.DisplayOrder = index + 1;
                    valueJson.Id = index + 1;
                    valueJson.DisplayName = displayName;
                    valuesJson.push(valueJson);
                }
            });
            attributeRow.ValueJson = valuesJson;
            const uniqueValues = new Set(attributeRow.ValueJson.map(x => x.Name));
            if (uniqueValues.size < attributeRow.ValueJson.length) {
                showWarningToast('Item already exist');
                return;
            }
            if (attributeRow.ValueJson.length < 1) {
                showWarningToast('Atleast 1 Item Required');
                return;
            }

            let progressDiv = showProgress('#createDropdownDiv');
            repositoryActions.postDataAndGetResponse(`${Constants.baseAPIUrl}Attribute/SaveColourCode`, getSaveRequest(attributeRow, attributeRow.AttributeUID), null, ContentType.applicationJson)
                .then((response) => {
                    hideProgress(progressDiv);
                    if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.EntityId)) {
                        if (this.props.onSave) {
                            this.props.onSave();
                        }
                    } else {
                        showWarningToast("Sorry something went wrong !");
                    }
                })
                .catch((error) => error);
        }
    };

    render() {
        if (isNotNullAndUndefined(this.props.attributeJM)) {
            return (<div className="px-3" id="createDropdownDiv">
                <RAFForm initialValues={this.props.attributeJM}
                    formRef={g => this.rafDropdownForm = g}
                    layout={RAFLayout.TwoColumnLayout}
                    onSubmit={this.onSubmit}
                    submitOnEnterKey={false}
                >
                    <div className="e-dlg-body-content">
                        <RAFChoiceColorOption<QueryAttributeJM> field="ValueJson" allowAdd={this.props.allowAdd} />
                    </div>
                    <div className="e-dlg-footerContent ">
                        <ButtonComponent type="submit" className="me-2" cssClass='e-flat e-info' id={`btn_save_manageChoiceList`}>Save</ButtonComponent>
                        <ButtonComponent type="button" cssClass='e-flat' onClick={this.props.onClose} id={`btn_cancel_manageChoiceList`}>Cancel</ButtonComponent>
                    </div>
                </RAFForm>
            </div>);
        }
        else {
            return <div>No data</div>;
        }

    }
}
export default ManageChoiceList;

