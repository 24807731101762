import { RichTextEditorComponent } from "@syncfusion/ej2-react-richtexteditor";
import arrayMutators from "final-form-arrays";
import { get, set } from "lodash";
import * as React from "react";
import { PropsWithChildren, ReactNode } from "react";
import { Field, Form, FormSpy, useField } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { createInstance } from "../../RAFComponents/Utility/FormUtility";
import {
  Guid,
  IsNullOrWhiteSpace,
  getSaveRequest,
  isNotNullAndUndefined,
  isNullOrUndefined,
} from "../../RAFComponents/helpers/utils";
import { RAFEntityBase } from "../../RAFComponents/models/Common/RAFEntityBase";
import * as repositoryActions from "../../RAFComponents/store/actions/repositoryActions";
import { ContentType, RAFLayout } from "../../constants/Common/Constants";
import {
  showErrorToast,
  showSuccessToast,
  showWarningToast
} from "../Utility/RAFToastComponent";
import { hideProgress, showProgress } from "../helpers/AppHelper";
import "./InputStyle.scss";
import RAFFormErrorMessage from "./RAFFormErrorMessage";
import { RAFFormContext, RAFFormProps } from "./RFFUtils";

interface ConditionNotIncludesProps {
  when: string;
  isNot: any;
  children?: ReactNode;
}

interface ConditionIncludesProps {
  when: string;
  is: any;
  children?: ReactNode;
}

export const RAFLayoutContext = React.createContext(RAFLayout.OneColumnLayout);

export const WhenFieldChanges = ({ field, set, to }) => (
  <Field name={set} subscription={{}}>
    {(
      // No subscription. We only use Field to get to the change function
      { input: { onChange } }
    ) => (
      <FormSpy subscription={{}}>
        {({ form }) => (
          <OnChange name={field}>
            {(value) => {
              onChange(to);
            }}
          </OnChange>
        )}
      </FormSpy>
    )}
  </Field>
);

export const WhenFieldChangesTo = ({ field, becomes, set, to }) => (
  <Field name={set} subscription={{}}>
    {(
      // No subscription. We only use Field to get to the change function
      { input: { onChange } }
    ) => (
      <FormSpy subscription={{}}>
        {({ form }) => (
          <OnChange name={field}>
            {(value) => {
              if (value === becomes) {
                onChange(to);
              }
            }}
          </OnChange>
        )}
      </FormSpy>
    )}
  </Field>
);

// value:string, is:[]
export const ConditionIncludes: React.FC<ConditionIncludesProps> = ({
  when,
  is,
  children,
}) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (is.includes(value) ? children : null)}
  </Field>
);

//value: [],is:string
export const ConditionItemExists: React.FC<ConditionIncludesProps> = ({
  when,
  is,
  children,
}) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => {
      return isNotNullAndUndefined(value) &&
        isNotNullAndUndefined(is) &&
        value.includes(is)
        ? children
        : null;
    }}
  </Field>
);

// value:string, is:[]
export const ConditionNotIncludes: React.FC<ConditionNotIncludesProps> = ({
  when,
  isNot,
  children,
}) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (!isNot.includes(value) ? children : null)}
  </Field>
);

export const Condition: React.FC<ConditionIncludesProps> = ({
  when,
  is,
  children,
}) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);

export const ConditionNot: React.FC<ConditionNotIncludesProps> = ({
  when,
  isNot,
  children,
}) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value !== isNot ? children : null)}
  </Field>
);

export const ConditionNotNullOrWhiteSpace = ({ when, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) =>
      value !== null &&
        value !== undefined &&
        value !== "undefined" &&
        value !== "" &&
        value !== " "
        ? children
        : null
    }
  </Field>
);

export const ConditionNullOrWhiteSpace = ({ when, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) =>
      value === null ||
        value === undefined ||
        value === "undefined" ||
        value === "" ||
        value === " "
        ? children
        : null
    }
  </Field>
);

export const RAFFieldError = ({ name }) => {
  const {
    meta: { touched, error },
  } = useField(name, { subscription: { touched: true, error: true } });
  return touched && error ? (
    <span className="fieldErrorMsg" id={`fieldError${name}`}>
      {error === "Required" ? "This field is required." : error}
    </span>
  ) : null;
};

export const composeValidators =
  (...validators) =>
    (value, allValues, fieldState) => {
      validators = validators.filter(function (e) {
        return e !== null;
      });
      return validators.reduce(
        (error, validator) => error || validator(value, allValues, fieldState),
        undefined
      );
    };

/*export const validateFormTypeByAdditionalInfo = (value, allValues, fieldState) => {
    if (isNotNullAndUndefined(allValues) && allValues["AdditionalInfo"] === true && IsNullOrWhiteSpace(value)) {
        return ('This field is required.');
    }
    return (undefined);
}*/

//const RAFForm = ({ initialValues, children, formRef, layout, onSubmit }) => {
function RAFForm<T>({
  initialValues,
  formRef,
  layout = RAFLayout.OneColumnLayout,
  onSubmit,
  type,
  primaryKey,
  onSave,
  decorators,
  submitOnEnterKey = true,
  convertBeforeSave,
  progressTarget,
  children,
  className,
  ...props
}: PropsWithChildren<RAFFormProps<T>>) {
  const formRef1 = React.useRef(null);
  const formId = "formDiv_" + Guid.newGuid();
  //const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
  const SubmitAndSave = (values) => {
    const progressDiv = showProgress(
      progressTarget ? progressTarget : "#" + formId
    );
    let objValues = null;
    if (isNotNullAndUndefined(convertBeforeSave)) {
      objValues = convertBeforeSave(values);
    } else {
      objValues = values;
    }
    //await sleep(1000)
    //console.log(JSON.stringify(values), objValues)
    //return;
    if (isNotNullAndUndefined(type)) {
      let objRAFEntity = createInstance(type) as RAFEntityBase;
      const url = objRAFEntity.getSaveUrl();
      if (isNotNullAndUndefined(url)) {
        repositoryActions
          .postDataAndGetResponse(
            url,
            getSaveRequest(objValues, primaryKey),
            null,
            ContentType.applicationJson
          )
          .then((response) => {
            hideProgress(progressDiv);
            if (
              isNotNullAndUndefined(response) &&
              isNotNullAndUndefined(response.data)
            ) {
              if (
                response.data.EntityId !== null &&
                response.data.EntityId !== undefined &&
                response.data.EntityId !== ""
              ) {
                //console.log('1', response.data.EntityId);
                showSuccessToast("Saved successfully");
                if (onSave) {
                  onSave(response.data.EntityId, response.data.ObjectName);
                }
              } else if (
                response.data.Error !== null &&
                response.data.Error !== undefined &&
                response.data.Error !== ""
              ) {
                showWarningToast("Sorry something went wrong !");
                //console.log("Error on save", response.data.Error);
              }
            }
          })
          .catch((error) => error);
      }
    }
  };

  const preventEnterKeySubmission = (e: React.KeyboardEvent) => {
    const target = e.target as HTMLInputElement | HTMLTextAreaElement;
    if (e.key === "Enter") {
      const objElement: HTMLElement = document.getElementById(target.id);
      //!["DIV"].includes(target.tagName)
      if (
        !["TEXTAREA"].includes(target.tagName) &&
        isNotNullAndUndefined(objElement) &&
        isNullOrUndefined(objElement.closest(".raf_prevent_submit"))
      ) {
        if (submitOnEnterKey === false) {
          e.preventDefault();
        }
      }
    }
  };

  function getSelectedUserIdArray(value) {
    let selectedUserIdArray: string[] = [];
    if (!IsNullOrWhiteSpace(value)) {
      let innerHTML = value;
      const div = document.createElement("div");
      div.innerHTML = innerHTML.trim();
      let tributeItem = div.getElementsByClassName("tribute-mention");
      if (isNotNullAndUndefined(tributeItem) && tributeItem.length > 0) {
        for (var i = 0; i < tributeItem.length; i++) {
          const objFirstItem = tributeItem[i];
          if (
            isNotNullAndUndefined(objFirstItem) &&
            isNotNullAndUndefined(objFirstItem.children) &&
            isNotNullAndUndefined(objFirstItem.children[0]) &&
            isNotNullAndUndefined(objFirstItem.children[0].id)
          ) {
            const objUserId = objFirstItem.children[0].id;
            if (isNotNullAndUndefined(objUserId)) {
              let userExist = false;
              if (isNotNullAndUndefined(selectedUserIdArray)) {
                //userExist = selectedUserIdArray.findIndex(x => x === objUserName) >= 0 ? true : false;
                userExist =
                  selectedUserIdArray.findIndex((x) => x === objUserId) >= 0
                    ? true
                    : false;
              }
              if (!userExist) {
                //selectedUserIdArray.push({ UID: objUserId, Value: objUserName });
                selectedUserIdArray.push(objUserId);
              }
            }
          }
        }
      }
    }
    return selectedUserIdArray;
  }

  // const validateForm = (

  // ) => {
  //   const richTextEditorElements = formRef1.current.querySelectorAll(
  //     ".rafRichTextEditorComponent"
  //   );

  //   console.log(formRef1.current);
  //   const values = formRef1.current.values;
  //   //loop through all richTextEditorElements and convert as ej2 RTE
  //   for (let i = 0; i < richTextEditorElements.length; i++) {
  //     if (
  //       isNotNullAndUndefined(richTextEditorElements[i]["ej2_instances"]) &&
  //       isNotNullAndUndefined(richTextEditorElements[i]["ej2_instances"][0]) &&
  //       isNotNullAndUndefined(
  //         richTextEditorElements[i][
  //         "ej2_instances"
  //         ][0] as RichTextEditorComponent
  //       )
  //     ) {
  //       const rte = richTextEditorElements[i][
  //         "ej2_instances"
  //       ][0] as RichTextEditorComponent;
  //       const rteName = rte["name"];
  //       if (rte.getHtml() === "<br>") {
  //         set(values, rteName, undefined);
  //       } else if (get(values, rteName) !== rte.getHtml()) {
  //         set(values, rteName, rte.getHtml());

  //         const mentionsField = rte.htmlAttributes["data-rafMentionsField"];

  //         if (isNotNullAndUndefined(mentionsField)) {
  //           const selectedUserIdArray = getSelectedUserIdArray(rte.getHtml());
  //           set(values, mentionsField, selectedUserIdArray);
  //         }
  //       }
  //     }
  //   }
  //   console.log(formRef1.current);
  //   return false;
  // };

  const handleSubmit = (values) => {
    //console.log('handlesubmit1', { ...values });
    // const richTextEditorElements = formRef1.current.querySelectorAll('.rafRichTextEditorComponent');

    // //loop through all richTextEditorElements and convert as ej2 RTE
    // for (let i = 0; i < richTextEditorElements.length; i++) {
    //     if (isNotNullAndUndefined(richTextEditorElements[i]['ej2_instances']) && isNotNullAndUndefined(richTextEditorElements[i]['ej2_instances'][0]) && isNotNullAndUndefined(richTextEditorElements[i]['ej2_instances'][0] as RichTextEditorComponent)) {
    //         const rte = richTextEditorElements[i]['ej2_instances'][0] as RichTextEditorComponent;
    //         if (rte.getHtml() === '<br>') {
    //             values[rte['name']] = '';
    //         }
    //         else if (values[rte['name']] !== rte.getHtml()) {
    //             values[rte['name']] = rte.getHtml();

    //             const mentionsField = rte.htmlAttributes['data-rafMentionsField'];

    //             if (isNotNullAndUndefined(mentionsField)) {
    //                 const selectedUserIdArray = getSelectedUserIdArray(rte.getHtml());
    //                 values[mentionsField] = selectedUserIdArray;
    //             }
    //         }
    //     }
    // }

    const richTextEditorElements = isNotNullAndUndefined(formRef1) && isNotNullAndUndefined(formRef1.current) ? formRef1.current.querySelectorAll(
      ".rafRichTextEditorComponent"
    ) : null;

    //console.log('handlesubmit2', { ...values });
    if (isNullOrUndefined(richTextEditorElements)) {
      //loop through all richTextEditorElements and convert as ej2 RTE
      for (let i = 0; i < richTextEditorElements.length; i++) {
        if (
          isNotNullAndUndefined(richTextEditorElements[i]["ej2_instances"]) &&
          isNotNullAndUndefined(richTextEditorElements[i]["ej2_instances"][0]) &&
          isNotNullAndUndefined(
            richTextEditorElements[i][
            "ej2_instances"
            ][0] as RichTextEditorComponent
          )
        ) {
          const rte = richTextEditorElements[i][
            "ej2_instances"
          ][0] as RichTextEditorComponent;
          const rteName = rte["name"];
          if (rte.getHtml() === "<br>") {
            set(values, rteName, undefined);
          } else if (get(values, rteName) !== rte.getHtml()) {
            set(values, rteName, rte.getHtml());

            const mentionsField = rte.htmlAttributes["data-rafMentionsField"];

            if (isNotNullAndUndefined(mentionsField)) {
              const selectedUserIdArray = getSelectedUserIdArray(rte.getHtml());
              set(values, mentionsField, selectedUserIdArray);
            }
          }
        }
      }
    }

    //console.log({ ...values });
    //return;
    if (onSubmit) {
      onSubmit(values);
    } else {
      SubmitAndSave(values);
    }
  };

  return (
    <RAFLayoutContext.Provider value={layout}>
      <Form
        //onSubmit={onSubmit || SubmitAndSave}
        onSubmit={handleSubmit}
        decorators={decorators}
        initialValues={initialValues}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
          ...arrayMutators,
        }}
        subscription={{
          submitting: true,
          pristine: true,
          values: true,
          invalid: true,
          errors: true,
        }}
        //render={({ handleSubmit, form, submitting, pristine, values }) => {
        render={(e) => {
          if (formRef) formRef(e);
          return (
            <form
              id={formId.toString()}
              onKeyDown={preventEnterKeySubmission}
              className={className}
              ref={formRef1}
              onSubmit={
                //e.handleSubmit
                (event) => {
                  if (e.invalid) {
                    //showErrorToast(JSON.stringify(e.errors, undefined, 2));
                    showErrorToast("Please provide all the required fields");
                  }
                  e.handleSubmit(event);
                }
              }

            // onKeyPress={(e) => {
            //     e.key === 'Enter' && !submitOnEnterKey && e.preventDefault();
            // }}
            // onKeyUp={(e) => {
            //     if (e.key === 'Enter' && !submitOnEnterKey) {
            //         e.preventDefault();
            //     }
            // }}
            // onKeyDown={(e) => {
            //     console.log('onKeyDown');
            //     if (e.key === 'Enter' && !submitOnEnterKey) {
            //         console.log('Enter');
            //         e.preventDefault();
            //     }
            // }}
            >
              {props.onChange && (
                <FormSpy
                  subscription={{ values: true }}
                  onChange={(props1) => {
                    props.onChange(props1.values);
                  }}
                />
              )}
              <RAFFormContext.Provider value={e}>
                {children}
                <RAFFormErrorMessage />
              </RAFFormContext.Provider>
            </form>
          );
        }}
      />
    </RAFLayoutContext.Provider>
  );
}

//RAFForm.propTypes = {
//    initialValues: PropTypes.object,
//    formRef: PropTypes.func,
//    onSubmit: PropTypes.func,
//    layout: PropTypes.oneOf([RAFLayout.OneColumnLayout, RAFLayout.TwoColumnLayout, RAFLayout.ThreeColumnLayout, RAFLayout.FourColumnLayout]),
//};

export default RAFForm;
