import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import React, { PropsWithChildren, useContext } from "react";
import { FormRenderProps } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import {
  RAFDefaultFieldProps,
  RAFFieldProps,
  RAFFormContext,
  getFormValue,
  setFormValue,
} from "./RFFUtils";
import { isNotEmptyArray, isNotNullAndUndefined } from "../helpers/utils";
import RAFTextBox from "./RAFTextBox";
import RAFNumber from "./RAFNumber";

interface IProps {
  showAdd?: boolean;
}

function RAFCustomTextRow<T>({
  field,
  showAdd = true,
}: PropsWithChildren<RAFFieldProps<T> & IProps>) {
  const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);

  const onAddClick = (field: keyof T | string) => {
    if (
      isNotNullAndUndefined(rafFormContextValue) &&
      isNotNullAndUndefined(rafFormContextValue.form) &&
      isNotNullAndUndefined(rafFormContextValue.form.mutators)
    ) {
      const fieldArray = getFormValue(
        rafFormContextValue,
        field.toString()
      ) as any[];
      const keyIndex =
        fieldArray && fieldArray.length > 0 ? fieldArray.length - 1 : 0;
      const lastOrder =
        fieldArray && fieldArray.length > 0
          ? fieldArray[fieldArray.length - 1].Order
          : 0;

      rafFormContextValue.form.mutators.push(field.toString(), {
        Order: lastOrder + 1,
        Type: "Question",
        TextKey: `question_key_${keyIndex + 1}`,
        TextValue: null,
      });

      rafFormContextValue.form.mutators.push(field.toString(), {
        Order: lastOrder + 2,
        Type: "Answer",
        TextKey: `answer_key_${keyIndex + 1}`,
        TextValue: null,
      });
    }
  };

  const onDeleteClick = (textKeyValue) => {
    const fieldArray = getFormValue(
      rafFormContextValue,
      field.toString()
    ) as any[];
    let updatedFieldArray = [];
    const key = textKeyValue.split("question_")[1];
    const questionKey = `question_${key}`;
    const answerKey = `answer_${key}`;
    if (isNotEmptyArray(fieldArray)) {
      updatedFieldArray = fieldArray.filter(
        (item) => item.TextKey !== questionKey && item.TextKey !== answerKey
      );
      setFormValue(rafFormContextValue, field.toString(), updatedFieldArray);
    }
  };

  return (
    <div className="app-container p-0" id={"rafdiv" + field.toString()}>
      <div className="list mb-3">
        <div className="row g-2 gy-4">
          <FieldArray name={field.toString()}>
            {({ fields }) =>
              fields.map((name, index) => (
                <div className="col-12" key={name}>
                  <div className="row g-2 align-items-center phoneSection">
                    <div className="col">
                      <RAFTextBox
                        field={`${name}.TextValue`}
                        label={
                          isNotNullAndUndefined(fields.value) &&
                          isNotNullAndUndefined(fields.value[index]) &&
                          fields.value[index]["Type"]
                        }
                      />
                      <div className="hidden">
                        <RAFNumber field={`${name}.Order`} label="Order" />
                        <RAFTextBox field={`${name}.Type`} label="Type" />
                        <RAFTextBox field={`${name}.TextKey`} label="Key" />
                      </div>
                    </div>

                    <div className="col-auto inputFieldDeleteButton">
                      <div>
                        {isNotNullAndUndefined(fields.value) &&
                          isNotNullAndUndefined(fields.value[index]) &&
                          fields.value[index]["Type"] === "Question" && (
                            <ButtonComponent
                              type="button"
                              onClick={() =>
                                onDeleteClick(
                                  isNotNullAndUndefined(fields.value) &&
                                  isNotNullAndUndefined(
                                    fields.value[index]
                                  ) &&
                                  fields.value[index]["TextKey"]
                                )
                              }
                              title="Click to remove"
                              className="removeItem"
                            >
                              <span className="fas fa-trash"></span>
                            </ButtonComponent>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </FieldArray>
          {showAdd && (
            <div className="col-12">
              <button
                type="button"
                className="link-button"
                onClick={() => onAddClick(field)}
              >
                {" "}
                Add Question{" "}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default RAFCustomTextRow;
