import { select } from "@syncfusion/ej2-base";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import {
  SuccessEventArgs,
  UploaderComponent,
  UploadingEventArgs,
} from "@syncfusion/ej2-react-inputs";
import React, { PropsWithChildren, useContext } from "react";
import { Field, FormRenderProps } from "react-final-form";
import { msalInstance } from "../..";
import { RAFAttributesContext } from "../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { RAFEntityContext } from "../../RAFModules/Common/Providers/RAFEntityProvider";
import {
  Constants,
  RAFButtonConstant,
  RAFHeaderNames,
} from "../../constants/Common/Constants";
import { showWarningToast } from "../Utility/RAFToastComponent";
import { hideProgress, showProgress } from "../helpers/AppHelper";
import {
  ConvertToElementID,
  IsNullOrWhiteSpace,
  getPureSubDomainOrHint,
  isEmptyArray,
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined,
} from "../helpers/utils";
import { LookUpRow } from "../models/CompositeTypes/LookUpRow";
import RAFFieldLabel from "./RAFFieldLabel";
import { RAFFieldError, composeValidators } from "./RAFForm";
import {
  RAFDefaultFieldClassName,
  RAFDefaultFieldProps,
  RAFFieldProps,
  RAFFormContext,
  getFormValue,
  isRequired,
  setFormValue,
} from "./RFFUtils";

import axios from "axios";
import { RAFProcessContext } from "../../RAFMaster/RMModules/BusinessProcess/ProcessLibrary/RAFProcessContextProvider";
import { ContentLibraryRow } from "../../RAFModules/ActiveContacts/Document/Library/ContentLibraryRow";
//import * as repositoryActions from "../store/actions/repositoryActions";
import { get, set } from "lodash";

interface IProps {
  isMultiSelect?: boolean;
  columnClass?: string;
  propertyName?: string;
}

function RAFFileAttachment<T>({
  field,
  label,
  children,
  labelClassName,
  description,
  descriptionAsLabel,
  columnClass,
  propertyName,
  isMultiSelect = false,

  required = RAFDefaultFieldProps.required,
  showLabel = RAFDefaultFieldProps.showLabel,
  disabled = RAFDefaultFieldProps.disabled,
  showClearButton = RAFDefaultFieldProps.showClearButton,
  validate = RAFDefaultFieldProps.validate,

  ...props
}: PropsWithChildren<IProps & RAFFieldProps<T>>) {
  const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);
  const rafProcessContext = useContext(RAFProcessContext);
  const entityContext = useContext(RAFEntityContext);
  const entity = isNotNullAndUndefined(entityContext)
    ? entityContext.entity
    : null;
  const entityCollectionName = isNotNullAndUndefined(entity)
    ? entity.CollectionName
    : null;
  const isAttachmentDownloadable = true;

  const attributeContext = useContext(RAFAttributesContext);
  const queryAttributes = attributeContext.queryAttributes;
  let selectedField =
    queryAttributes &&
    queryAttributes.find((x) => x.PropertyName === propertyName);
  const businessProcess = isNotNullAndUndefined(rafProcessContext)
    ? rafProcessContext.businessProcess
    : null;

  const relatedDocuments = get(rafFormContextValue.values, field.toString());
  //const relatedDocuments = getFormValue(rafFormContextValue, field.toString());

  const fieldLength =
    isMultiSelect === true
      ? isNotEmptyArray(relatedDocuments)
        ? relatedDocuments.length
        : 0
      : null;

  const attachmentDivID = ConvertToElementID(field.toString());

  let uploadObj: UploaderComponent;
  let progressDiv;

  const asyncSettings: object = {
    saveUrl: `${Constants.baseAPIUrl}ContentLibrary/Upload`,
  };

  function addAdditionalHeaders(args: UploadingEventArgs) {
    // add addition data as key-value pair.
    (args.currentRequest as XMLHttpRequest).setRequestHeader(
      RAFHeaderNames.Authorization,
      `Bearer ${msalInstance.accessToken}`
    );
    (args.currentRequest as XMLHttpRequest).setRequestHeader(
      RAFHeaderNames.BusinessUnitUID,
      msalInstance.currentBusinessUnitId
    );
    (args.currentRequest as XMLHttpRequest).setRequestHeader(
      RAFHeaderNames.Domain,
      getPureSubDomainOrHint()
    );
    args.customFormData = [
      {
        Entity: entityCollectionName,
      },
      {
        SecondaryRelatedToUID: isNotNullAndUndefined(selectedField)
          ? selectedField.AttributeUID
          : null,
      },
      {
        SecondaryRelatedTo: isNotNullAndUndefined(selectedField)
          ? selectedField.PropertyName
          : null,
      },
      {
        RelatedTo: isNotNullAndUndefined(businessProcess)
          ? businessProcess.RelatedTo
          : null,
      },
      {
        RelatedToUID: isNotNullAndUndefined(businessProcess)
          ? businessProcess.RelatedToUID
          : null,
      },
      {
        BusinessProcessUID: isNotNullAndUndefined(businessProcess)
          ? businessProcess.UID
          : null,
      },
      {
        BusinessProcess: isNotNullAndUndefined(businessProcess)
          ? businessProcess.ProcessName
          : null,
      },
    ];
    progressDiv = showProgress("#newComposeMailDialog");
  }

  function onSuccessUpload(args: SuccessEventArgs) {
    hideProgress(progressDiv);
    let uploadedFile = JSON.parse(args.e["target"].responseText);
    let objArray = uploadedFile.documentUIDs;
    const attachmentDiv = document.querySelector("#attachmentDiv");

    let entityId;
    let objectName;
    let attachmentsRow: LookUpRow[] = [];
    if (isNotEmptyArray) {
      entityId = objArray[0].EntityId;
      objectName = objArray[0].ObjectName;
      attachmentsRow.push({
        UID: objArray[0].EntityId,
        Value: objArray[0].ObjectName,
      });
    }

    if (isNotNullAndUndefined(attachmentDiv)) {
      attachmentDiv.classList.add("fade-in");
      attachmentDiv.classList.remove("hidden");
    }
    if (
      isNotNullAndUndefined(rafFormContextValue) &&
      isNotNullAndUndefined(rafFormContextValue.form) &&
      isNotNullAndUndefined(rafFormContextValue.form.mutators)
    ) {
      if (isMultiSelect) {
        rafFormContextValue.form.mutators.push(
          field.toString(),
          ...attachmentsRow
        );
      } else {
        setFormValue(rafFormContextValue, field.toString() + "UID", entityId);
        setFormValue(rafFormContextValue, field.toString(), objectName);
      }
    } else {
      showWarningToast("Sorry something went wrong !");
    }
    uploadObj && uploadObj.refresh();
    let browsebtn = document.getElementById(`browse_${attachmentDivID}`);
    if (isNotNullAndUndefined(browsebtn)) browsebtn.classList.add("hidden");
  }

  const AttachButtonClicked = () => {
    let browsebtn = document.getElementById(`browse_${attachmentDivID}`);
    if (isNotNullAndUndefined(browsebtn)) {
      const wrapperEle: HTMLElement = select(
        `#browse_${attachmentDivID} .e-file-select-wrap button`,
        document
      ) as HTMLElement;
      wrapperEle.click();
    }
  };

  const getAttachmentInputValue = (fieldValue, uidValue) => {
    let displayValue: LookUpRow[] = [];
    if (isMultiSelect) {
      if (isNotEmptyArray(fieldValue)) {
        fieldValue.forEach((item) => {
          if (
            isNotNullAndUndefined(item.UID) &&
            isNotNullAndUndefined(item.Value)
          ) {
            displayValue.push({
              UID: item.UID,
              Value: item.Value,
            });
          }
        });
      }
    } else {
      const uid = getFormValue(rafFormContextValue, field.toString() + "UID");
      const value = getFormValue(rafFormContextValue, field.toString());
      if (!IsNullOrWhiteSpace(uidValue) && !IsNullOrWhiteSpace(fieldValue)) {
        displayValue = [{ UID: uidValue, Value: fieldValue }];
      }
    }
    return displayValue;
  };

  const DownloadAttachment = (fileName: string, id: string) => {
    progressDiv = showProgress("body");
    let objData = { EntityId: id };

    let url = `${Constants.baseAPIUrl}ContentLibrary/Download`;
    axios({
      url: url,
      method: "POST",
      responseType: "blob",
      headers: {
        [RAFHeaderNames.Authorization]: `Bearer ${msalInstance.accessToken}`,
        [RAFHeaderNames.BusinessUnitUID]: msalInstance.currentBusinessUnitId,
        [RAFHeaderNames.Domain]: getPureSubDomainOrHint(),
      },
      data: objData,
    }).then((response) => {
      if (isNotNullAndUndefined(response)) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;

        let extension;
        let displayName = fileName;

        if (isNotNullAndUndefined(displayName)) {
          let x = displayName.split(".").pop();
          extension = isNotNullAndUndefined(x) ? x : null;
        }

        if (isNotNullAndUndefined(fileName)) {
          let y = fileName.split(".").pop();
          if (isNotNullAndUndefined(y) && extension !== y) {
            extension = y;
          } else {
            extension = null;
          }
        }

        let downloadDocName = "Notset.txt";

        if (isNotNullAndUndefined(extension)) {
          if (isNotNullAndUndefined(displayName)) {
            downloadDocName = displayName + "." + extension;
          } else if (isNotNullAndUndefined(fileName)) {
            downloadDocName = fileName + "." + extension;
          }
        } else if (isNotNullAndUndefined(displayName)) {
          downloadDocName = displayName;
        } else if (isNotNullAndUndefined(fileName)) {
          downloadDocName = fileName;
        }

        link.setAttribute("download", downloadDocName);
        document.body.appendChild(link);
        link.click();
        hideProgress(progressDiv);
      } else {
        hideProgress(progressDiv);
      }
    });
  };

  // const RemoveAttachment = (id) => {
  //     let progressElement = document.getElementById(`rafdiv${field.toString}`).closest('.e-dialog') as HTMLElement;
  //     let progressDiv = showProgress(progressElement);
  //     let url = `${Constants.baseAPIUrl}ContentLibrary/Delete`;
  //     repositoryActions
  //         .postDataAndGetResponse(
  //             url,
  //             { EntityId: id },
  //             null,
  //             ContentType.applicationJson
  //         )
  //         .then((response) => {
  //             hideProgress(progressDiv);
  //             if (isNotNullAndUndefined(response)) {
  //                 onRemoveAttachment(id);
  //             }
  //         })
  //         .catch((error) => error);
  // };

  const onRemoveAttachment = (id) => {
    if (isMultiSelect) {
      const fieldValueArray: LookUpRow[] = getFormValue(
        rafFormContextValue,
        field.toString()
      );
      let newFieldArray = isNotEmptyArray(fieldValueArray)
        ? fieldValueArray.filter((x) => x.UID !== id)
        : null;
      if (isEmptyArray(newFieldArray)) {
        newFieldArray = null;
      }
      setFormValue(rafFormContextValue, field.toString(), newFieldArray);
    } else {
      setFormValue(rafFormContextValue, field.toString() + "UID", null);
      setFormValue(rafFormContextValue, field.toString(), null);
    }
  };

  const contentLibraryRowTemplate = (data: LookUpRow) => {
    const showRemove = isNullOrUndefined(entityCollectionName)
      ? false
      : disabled === true
        ? false
        : true;

    return (
      <div
        className={
          isNotNullAndUndefined(columnClass)
            ? columnClass
            : "col-sm-6 col-lg-4 col-xl-3"
        }
      >
        <div
          className="e-card"
          key={data.UID}
          id={data.UID}
          onClick={() =>
            isAttachmentDownloadable === true
              ? DownloadAttachment(data.Value, data.UID)
              : null
          }
        >
          <div className="e-card-content px-3 p-2 d-flex align-items-center">
            <span className="me-2">
              <span
                className={"fa fa-file-alt"}
                style={{ fontSize: "18px" }}
              ></span>
            </span>
            <span className="secondary-header-bold-text">{data.Value}</span>

            {showRemove && (
              <span
                title="Remove"
                className="fas fa-trash removeAttachment ms-auto"
                onClick={(e) => {
                  e.stopPropagation();
                  onRemoveAttachment(data.UID);
                }}
              ></span>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={
        isNotNullAndUndefined(props.formGroupClassName)
          ? props.formGroupClassName + " form-group"
          : "form-group"
      }
    >
      <div
        className={
          props.rowClassName
            ? props.rowClassName + " row"
            : RAFDefaultFieldClassName.rowClassName
        }
        id={`rafdiv_${attachmentDivID}`}
        key={`rafdiv_${attachmentDivID}`}
      >
        {showLabel && showLabel === true && (
          <RAFFieldLabel
            field={field}
            label={label}
            iconCss={
              isMultiSelect === false && isNotNullAndUndefined(relatedDocuments)
                ? "fas fa-badge-check text__Green1 ps-1"
                : null
            }
            required={required}
            labelClassName={labelClassName}
            {...(isMultiSelect ? { count: fieldLength } : {})}
            description={description}
            descriptionAsLabel={descriptionAsLabel}
            rightSection={
              (isMultiSelect === true ||
                (isMultiSelect === false &&
                  isNullOrUndefined(relatedDocuments))) && (
                <ButtonComponent
                  type="button"
                  cssClass="link-button ms-2"
                  iconCss={RAFButtonConstant.Attach.IconCss}
                  disabled={
                    isNullOrUndefined(entityCollectionName)
                      ? true
                      : disabled === true
                        ? true
                        : false
                  }
                  onClick={AttachButtonClicked}
                  content={RAFButtonConstant.Upload.DisplayName}
                ></ButtonComponent>
              )
            }
          />
        )}
        <div className={"col-12"}>
          <Field name={field.toString() + "UID"}>
            {({ input }) => {
              const uidValue = input.value;
              return (
                <Field
                  name={field.toString()}
                  {...(props.initialValue
                    ? { initialValue: props.initialValue }
                    : {})}
                  //validate={validate === true ? (required && isRequired) : null}
                  {...(props.validators
                    ? {
                      validate:
                        validate === true
                          ? composeValidators(
                            required === true ? isRequired : null,
                            ...props.validators
                          )
                          : null,
                    }
                    : {
                      validate:
                        validate === true
                          ? composeValidators(
                            required === true ? isRequired : null
                          )
                          : null,
                    })}
                  allowNull
                  parse={(value) => (value === "" ? null : value)}
                >
                  {({ input }) => {
                    const displayValue: LookUpRow[] = getAttachmentInputValue(
                      input.value,
                      uidValue
                    );
                    const displayValue1: ContentLibraryRow[] =
                      getAttachmentInputValue(input.value, uidValue);

                    return (
                      <div>
                        <div
                          className="row align-items-center"
                          id="attachmentDiv"
                        >
                          <div className="col-12 d-flex">
                            {showLabel !== true &&
                              (isMultiSelect === true ||
                                (isMultiSelect === false &&
                                  isNullOrUndefined(relatedDocuments))) && (
                                <ButtonComponent
                                  type="button"
                                  cssClass="link-button"
                                  iconCss={RAFButtonConstant.Attach.IconCss}
                                  disabled={
                                    isNullOrUndefined(entityCollectionName)
                                      ? true
                                      : disabled === true
                                        ? true
                                        : false
                                  }
                                  onClick={AttachButtonClicked}
                                  content={RAFButtonConstant.Upload.DisplayName}
                                ></ButtonComponent>
                              )}
                            <div
                              id={`browse_${attachmentDivID}`}
                              className="hidden"
                            >
                              <UploaderComponent
                                id="fileUpload"
                                type="file"
                                ref={(dialog) => (uploadObj = dialog)}
                                asyncSettings={asyncSettings}
                                uploading={addAdditionalHeaders.bind(this)}
                                success={onSuccessUpload.bind(this)}
                                //actionComplete={onUploadCompleted.bind(this)}
                                minFileSize={1}
                                multiple={false}
                              //multiple={isMultiSelect ?? false}
                              //multiple={isMultiSelect ?? false}
                              >
                                {/* <FilesDirective>
                                                        {displayValue.map((item, index) => {
                                                            console.log('displayValue -index---', index, '---', item);
                                                            return (
                                                                <UploadedFilesDirective key={index} name={item.Value} />
                                                            );
                                                        })}
                                                    </FilesDirective> */}
                              </UploaderComponent>
                            </div>
                          </div>
                          <div className="col-12">
                            <div
                              className="row g-3"
                              style={{ flexWrap: "wrap" }}
                              id={`rafdiv${attachmentDivID}`}
                            >
                              {isNotEmptyArray(displayValue) &&
                                displayValue.map((data) => {
                                  return contentLibraryRowTemplate(data);
                                })}
                            </div>
                          </div>
                        </div>

                        {props.hideRequiredMessage !== true ? (
                          <RAFFieldError name={field.toString()} />
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  }}
                </Field>
              );
            }}
          </Field>
        </div>
      </div>
    </div>
  );
}

export default React.memo(RAFFileAttachment);
