import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import React, { PropsWithChildren, ReactNode } from "react";
import { Field } from "react-final-form";
import { IsNotNullOrWhiteSpace, isNotNullAndUndefined } from "../helpers/utils";
import RAFFieldLabel from "./RAFFieldLabel";
import { RAFFieldError, composeValidators } from "./RAFForm";
import { RAFDefaultFieldClassName, RAFDefaultFieldProps, RAFFieldProps, hasEmoji, isRequired } from "./RFFUtils";

interface IProps {
    interval?: number;
    labelRightSection?: React.ReactFragment | ReactNode;
}

function RAFDurationPicker<T>({
    field,
    label,
    width,
    created,
    onChanged,
    fieldInnerText,
    children,
    maxlength,
    description,
    descriptionAsLabel,
    titleLocation,

    interval = 5,
    required = RAFDefaultFieldProps.required,
    showLabel = RAFDefaultFieldProps.showLabel,
    disabled = RAFDefaultFieldProps.disabled,
    showClearButton = RAFDefaultFieldProps.showClearButton,
    validate = RAFDefaultFieldProps.validate,
    ...props }: PropsWithChildren<RAFFieldProps<T> & IProps>) {
    let hrsdropDownListComponent: DropDownListComponent;
    let minsdropDownListComponent: DropDownListComponent;

    let labelClassName = isNotNullAndUndefined(props.labelClassName)
        ? props.labelClassName
        : "";
    let rowClassName = isNotNullAndUndefined(props.rowClassName)
        ? `${props.rowClassName} row`
        : RAFDefaultFieldClassName.rowClassName;
    let inputFieldClassName = isNotNullAndUndefined(props.inputFieldClassName)
        ? props.inputFieldClassName
        : "col-12";
    if (titleLocation === "Right") {
        rowClassName = `${rowClassName} flex-nowrap gx-2`;
        labelClassName = "col-auto order-last";
        inputFieldClassName = "col";
    } else if (titleLocation === "Left") {
        rowClassName = `${rowClassName} flex-nowrap gx-2`;
        labelClassName = "col-3";
        inputFieldClassName = "col";
    } else if (titleLocation === "Bottom") {
        labelClassName = "order-last";
    }

    const fields = {
        text: "text",
        value: "value",
    };

    const hrs24DropdownItems = Array.from({ length: 24 }, (v, i) => ({
        key: i,
        text: `${i} hour${i !== 1 ? 's' : ''}`,
        value: i,
    }));

    const hrs12DropdownItems = Array.from({ length: 13 }, (v, i) => ({
        key: i,
        text: `${i} hour${i !== 1 ? 's' : ''}`,
        value: i,
    }));

    const intervalValue = isNotNullAndUndefined(interval) ? interval : 5;

    const minsDropdownItems = Array.from(
        { length: Math.ceil(60 / intervalValue) },
        (v, i) => ({
            key: i,
            text: `${i * intervalValue} minute${i * intervalValue !== 1 ? 's' : ''}`,
            value: i * intervalValue,
        })
    );

    const getHrsDisplayValue = (minsValue) => {
        if (IsNotNullOrWhiteSpace(minsValue)) {
            const mins = parseInt(minsValue);
            if (mins > 0) {
                return Math.floor(mins / 60);
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    };

    const getMinsDisplayValue = (minsValue) => {
        if (IsNotNullOrWhiteSpace(minsValue)) {
            const mins = parseInt(minsValue);
            if (mins > 0) {
                return mins % 60;
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    };

    const onChangeHrs = (hrsValue, input) => {
        const formValue = input.value;

        const minsNumber = IsNotNullOrWhiteSpace(formValue) ? parseInt(formValue) : 0;
        const mins = minsNumber % 60;

        const hrs = parseInt(hrsValue);
        const totalMins = (hrs * 60) + mins;

        input.onChange(totalMins);
        if (isNotNullAndUndefined(onChanged)) {
            onChanged(totalMins);
        }
    };

    const onChangeMins = (minsValue, input) => {
        const formValue = input.value;

        const hrsNumber = IsNotNullOrWhiteSpace(formValue) ? parseInt(formValue) : 0;
        const hrs = Math.floor(hrsNumber / 60);

        const mins = parseInt(minsValue);
        const totalMins = (hrs * 60) + mins;

        input.onChange(totalMins);
        if (isNotNullAndUndefined(onChanged)) {
            onChanged(totalMins);
        }
    };

    return (
        <div
            className={
                isNotNullAndUndefined(props.formGroupClassName)
                    ? props.formGroupClassName + " form-group"
                    : "form-group"
            }
        >
            <div className={rowClassName} id={"rafdiv" + field.toString()}>
                {showLabel && showLabel === true && (
                    <RAFFieldLabel
                        field={field}
                        label={label}
                        required={required}
                        labelClassName={labelClassName}
                        description={description}
                        descriptionAsLabel={descriptionAsLabel}
                        rightSection={props.labelRightSection}
                    />
                )}
                <Field
                    name={field.toString()}
                    {...(props.initialValue ? { initialValue: props.initialValue } : {})}
                    //validate={validate === true ? (required && isRequired) : null}
                    {...(props.validators
                        ? {
                            validate:
                                validate === true
                                    ? composeValidators(
                                        required === true ? isRequired : null,
                                        hasEmoji,
                                        ...props.validators
                                    )
                                    : null,
                        }
                        : {
                            validate:
                                validate === true
                                    ? composeValidators(
                                        required === true ? isRequired : null
                                    )
                                    : null,
                        })}
                    allowNull
                    parse={(value) => (value === "" ? null : value)}
                >
                    {({ input, meta }) => (
                        <div className={inputFieldClassName}>
                            <div
                                className={
                                    isNotNullAndUndefined(fieldInnerText)
                                        ? "fieldInnerText"
                                        : ""
                                }
                            >
                                <div className="row gx-2">
                                    <div className="col">
                                        <DropDownListComponent
                                            id={`ddl_hrs_${field.toString()}`}
                                            name={input.name}
                                            value={getHrsDisplayValue(input.value)}
                                            ref={(d) =>
                                                (hrsdropDownListComponent = d)
                                            }
                                            change={(e) => {
                                                if (e.isInteracted) {
                                                    onChangeHrs(e.value, input);
                                                }
                                            }}
                                            dataSource={getHrsDisplayValue(input.value) > 12 ? hrs24DropdownItems : hrs12DropdownItems}
                                            fields={fields}
                                            allowFiltering={true}
                                            readonly={disabled}
                                            filterType="Contains"
                                            cssClass={
                                                meta.error && meta.touched
                                                    ? "inputFieldError"
                                                    : null
                                            }
                                            showClearButton={false}
                                        />
                                    </div>
                                    <div className="col">
                                        <DropDownListComponent
                                            id={`ddl_mins_${field.toString()}`}
                                            name={input.name}
                                            value={getMinsDisplayValue(input.value)}
                                            ref={(d) =>
                                                (minsdropDownListComponent = d)
                                            }
                                            change={(e) => {
                                                if (e.isInteracted) {
                                                    onChangeMins(e.value, input);
                                                }
                                            }}
                                            fields={fields}
                                            dataSource={minsDropdownItems}
                                            allowFiltering={true}
                                            readonly={disabled}
                                            filterType="Contains"
                                            cssClass={
                                                meta.error && meta.touched
                                                    ? "inputFieldError"
                                                    : null
                                            }
                                            showClearButton={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            {props.hideRequiredMessage !== true ? (
                                <RAFFieldError name={field.toString()} />
                            ) : (
                                ""
                            )}
                        </div>
                    )}
                </Field>
            </div>
        </div>
    );
}

export default React.memo(RAFDurationPicker);