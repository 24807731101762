import React, { PropsWithChildren } from "react";
import { Field } from "react-final-form";
import { Guid, isNotNullAndUndefined } from "../../RAFComponents/helpers/utils";
import { GetFieldsDisplayName, GetSelectedField } from "../helpers/AppHelper";
import { RAFAttributesContext } from "../Providers/RAFAttributeRelatedListProvider";
import "./InputStyle.scss";
import RAFFieldLabel from "./RAFFieldLabel";
import { composeValidators } from "./RAFForm";
import {
  isRequired,
  RAFCheckBoxListFieldProps,
  RAFDefaultFieldClassName,
  RAFDefaultFieldProps,
} from "./RFFUtils";

const getDataFromChildren = (children?) => {
  let retVal = [];
  if (isNotNullAndUndefined(children)) {
    React.Children.forEach(children, (child) => {
      retVal.push({
        ...child["props"],
        label: child["props"]["children"] || child["props"]["label"],
      });
    });
  }
  return retVal;
};

function RAFCheckBoxList<T>({
  field,
  label,
  //onChanged,
  children,
  formGroupClassName,
  description,
  descriptionAsLabel,
  uitype = "default",
  required = RAFDefaultFieldProps.required,
  showLabel = RAFDefaultFieldProps.showLabel,
  disabled = RAFDefaultFieldProps.disabled,
  showClearButton = RAFDefaultFieldProps.showClearButton,
  validate = RAFDefaultFieldProps.validate,
  ...props
}: PropsWithChildren<RAFCheckBoxListFieldProps<T>>) {
  let items = getDataFromChildren(React.Children.toArray(children));
  let labelClassName = isNotNullAndUndefined(props.labelClassName)
    ? " " + props.labelClassName
    : "";

  return (
    <div
      className={
        isNotNullAndUndefined(formGroupClassName)
          ? formGroupClassName + " form-group"
          : "form-group"
      }
    >
      <div className={RAFDefaultFieldClassName.rowClassName} id={"rafdiv" + field.toString()}>
        {showLabel && showLabel === true && (
          <RAFFieldLabel
            field={field}
            label={label}
            required={required}
            labelClassName={labelClassName}
            description={description}
            descriptionAsLabel={descriptionAsLabel}
            unsetHeight
          ></RAFFieldLabel>
        )}
        <div className="col-12">
          <div
            className={
              uitype === "vertical"
                ? "d-flex flex-column"
                : "d-flex flex-wrap"
            }
          >
            {items.map((item, index) => {
              let indexVal: string = Guid.newGuid();
              return (
                <div className="custom-checkbox me-2 mt-2" key={index}>
                  <Field
                    name={field.toString()}
                    component="input"
                    type="checkbox"
                    value={item.value}
                    className="custom-control-input"
                    id={item.value + "_" + indexVal}
                    {...(props.initialValue
                      ? { initialValue: props.initialValue }
                      : {})}
                    //validate={validate === true ? (required && isRequired) : null}
                    {...(props.validators
                      ? {
                        validate:
                          validate === true
                            ? composeValidators(
                              required === true ? isRequired : null,
                              ...props.validators
                            )
                            : null,
                      }
                      : {
                        validate:
                          validate === true
                            ? composeValidators(
                              required === true ? isRequired : null
                            )
                            : null,
                      })}
                    allowNull
                    parse={(value) => (value === "" ? null : value)}
                  />

                  <label
                    className="custom-control-label"
                    htmlFor={item.value + "_" + indexVal}
                  >
                    <span className="ms-2">{item.label}</span>
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RAFCheckBoxList;
