import { ButtonComponent, ChipDirective, ChipListComponent, ChipsDirective } from '@syncfusion/ej2-react-buttons';
import { PropsWithChildren, Reducer, useReducer } from "react";
import { Field, FieldInputProps } from 'react-final-form';
import { showWarningToast } from '../Utility/RAFToastComponent';
import RAFAutoCompleteMUITextBox from '../helpers/RAFAutoCompleteMUITextBox';
import { IsNotNullOrWhiteSpace, isNotEmptyArray, isNotNullAndUndefined } from '../helpers/utils';
import RAFFieldLabel from './RAFFieldLabel';
import { RAFFieldError, composeValidators } from './RAFForm';
import { RAFDefaultFieldClassName, RAFDefaultFieldProps, RAFTagInputFieldProps, hasEmoji, isRequired } from './RFFUtils';

interface IProps {
    moduleName: string;
}

interface IState {
    showAutoCompleteMUITextBox: boolean;
    autoCompleteMUITextBoxKey: number;
}

function RAFAutoCompleteMUITagInput<T>(
    { field, onChanged, showAddBtn, moduleName, children, label, titleLocation,
        description,
        required = RAFDefaultFieldProps.required,
        showLabel = RAFDefaultFieldProps.showLabel,
        disabled = RAFDefaultFieldProps.disabled,
        showClearButton = RAFDefaultFieldProps.showClearButton,
        validate = RAFDefaultFieldProps.validate,
        ...props }: PropsWithChildren<RAFTagInputFieldProps<T> & IProps>,
) {
    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            showAutoCompleteMUITextBox: false,
            autoCompleteMUITextBoxKey: Math.random()
        }
    );


    let inputFieldClassName = isNotNullAndUndefined(props.inputFieldClassName)
        ? props.inputFieldClassName
        : "col-12";
    let rowClassName = isNotNullAndUndefined(props.rowClassName)
        ? `${props.rowClassName} row`
        : RAFDefaultFieldClassName.rowClassName;
    let labelClassName = isNotNullAndUndefined(props.labelClassName)
        ? props.labelClassName
        : "";
    if (titleLocation === "Right") {
        rowClassName = `${rowClassName} flex-nowrap gx-2`;
        labelClassName = "col-auto order-last";
        inputFieldClassName = "col";
    } else if (titleLocation === "Left") {
        rowClassName = `${rowClassName} flex-nowrap gx-2`;
        labelClassName = "col-3";
        inputFieldClassName = "col";
    } else if (titleLocation === "Bottom") {
        labelClassName = "order-last";
    }


    const removeTagData = (indexToRemove, input, inputValue) => {
        if (disabled === false) {
            const tagData = inputValue;
            let tagUpdatedData = [];
            if (isNotNullAndUndefined(tagData)) {
                tagUpdatedData = tagData && [...tagData.filter((_, index) => index !== indexToRemove)];
            }
            if (!isNotEmptyArray(tagUpdatedData)) {
                tagUpdatedData = null;
            }
            input.onChange(tagUpdatedData);
            if (isNotNullAndUndefined(onChanged)) {
                onChanged(tagUpdatedData);
            }
        }

    };

    const showTagsInputField = () => {
        setState({ showAutoCompleteMUITextBox: true, autoCompleteMUITextBoxKey: Math.random() });
    };

    const hideTagsInputField = () => {
        setState({ showAutoCompleteMUITextBox: false, autoCompleteMUITextBoxKey: Math.random() });
    };

    const onChangeAutoCompleteMUI = (value: string, uid: string, input, inputValue) => {
        if (IsNotNullOrWhiteSpace(value)) {
            if (!hasEmoji(value)) {
                const tagData = inputValue;
                let tagUpdatedData = [];
                if (isNotNullAndUndefined(tagData)) {
                    //check is item already exists
                    const existingItem = tagData.some(x => x.toLowerCase() === value.toLowerCase());
                    if (existingItem) {
                        showWarningToast(`Tag "${value}" already exists.`);
                        return;
                    }
                    tagUpdatedData = [...tagData, value];
                } else {
                    tagUpdatedData[0] = value;
                }
                input.onChange(tagUpdatedData);
                if (isNotNullAndUndefined(onChanged)) {
                    onChanged(tagUpdatedData);
                }
                hideTagsInputField();
            } else {
                showWarningToast(`${hasEmoji(value)}`);
            }
        } else {
            hideTagsInputField();
        }
    };

    const getAutoCompleteMUI = (input: FieldInputProps<any, HTMLElement>, inputValue) => {
        return (
            <RAFAutoCompleteMUITextBox
                key={state.autoCompleteMUITextBoxKey}
                inputName={`input_addTag_${field.toString()}`}
                customFilter={null}
                inputValue={null}
                moduleName={moduleName}
                onChanged={(label, value) => onChangeAutoCompleteMUI(label, value, input, inputValue)}
                preventOnChangeOnBlur={true}
                placeholder='Type and press enter to add a tag'
                setUIDAsValueField
                url={"Tag/LookUpDropDown"}
            />
        );
    };

    return (
        <div className="app-container p-0 raf_mui_input_tags"
            //id={"rafdiv" + field.toString()}
            id={`rafdiv_${field.toString()}`}
        >
            <Field name={field.toString()}
                {...props.initialValue ? { initialValue: props.initialValue } : {}}
                //validate={validate === true ? (required && isRequired) : null}
                {...props.validators ?
                    { validate: validate === true ? composeValidators(required === true ? isRequired : null, ...props.validators) : null }
                    :
                    { validate: validate === true ? composeValidators(required === true ? isRequired : null) : null }
                }
                allowNull parse={value => (value === "" ? null : value)}>
                {({ input }) => {
                    let inputValue = isNotNullAndUndefined(input) && isNotEmptyArray(input.value) ? input.value : [];
                    return (
                        <div>
                            {showAddBtn ?
                                <div>
                                    <div className={rowClassName}>
                                        {showLabel ?
                                            <RAFFieldLabel field={field} label={label} required={required}
                                                description={description}
                                                labelClassName={labelClassName}></RAFFieldLabel>
                                            :
                                            ""}
                                        <div className={`${inputFieldClassName}`}>
                                            <div
                                                className={rowClassName}
                                            >
                                                {isNotNullAndUndefined(inputValue) && inputValue.length > 0 &&
                                                    <div className='col-auto'>
                                                        <ChipListComponent id="chip-avatar" enableDelete={disabled === true ? false : true} deleted={e => removeTagData(e.index, input, inputValue)}>
                                                            <ChipsDirective>
                                                                {inputValue.map((tag, index) => (
                                                                    <ChipDirective key={index} text={tag} ></ChipDirective>
                                                                ))}
                                                            </ChipsDirective>
                                                        </ChipListComponent>
                                                    </div>
                                                }
                                                {!disabled &&
                                                    state.showAutoCompleteMUITextBox === true && (
                                                        <div className="col-auto d-flex">
                                                            <div id={`tagInputField`} className="raf_mui_input" >
                                                                {getAutoCompleteMUI(input, inputValue)}
                                                                <ButtonComponent type="button" iconCss='fas fa-xmark'
                                                                    id={`btn_closeTag_${field.toString()}`}
                                                                    onClick={() => hideTagsInputField()} title="Click to remove" cssClass="primary-custom-button e-outline border-0 ms-2 custom-button-lg custom-button-lg_40">
                                                                </ButtonComponent>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                {!disabled && !state.showAutoCompleteMUITextBox &&
                                                    <div className="col-auto" id={`tagButton`}
                                                    // className="d-none d-sm-flex"
                                                    >
                                                        <ButtonComponent type="button"
                                                            cssClass="link-button p-0 custom-button-lg custom-button-lg_40"
                                                            onClick={() => showTagsInputField()}
                                                            id={`btn_addTag_${field.toString()}`}
                                                        >Add Tag</ButtonComponent>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="hideAddBtn raf_mui_input">
                                    {isNotNullAndUndefined(inputValue) && inputValue.length > 0 &&
                                        <ChipListComponent id="chip-avatar" enableDelete click={e => removeTagData(e.index, input, inputValue)}>
                                            <ChipsDirective>
                                                {inputValue.map((tag, index) => (
                                                    <ChipDirective key={index} text={tag} ></ChipDirective>
                                                ))}
                                            </ChipsDirective>
                                        </ChipListComponent>
                                    }
                                    {!disabled &&
                                        getAutoCompleteMUI(input, inputValue)
                                    }
                                </div>
                            }
                            {props.hideRequiredMessage !== true ? (
                                <RAFFieldError name={field.toString()} />
                            ) : (
                                ""
                            )}
                        </div>
                    );
                }}
            </Field>
        </div >
    );
}

export default RAFAutoCompleteMUITagInput;