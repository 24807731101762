import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import * as React from "react";
import { Fragment, PropsWithChildren } from "react";
import { Field, FormRenderProps } from "react-final-form";
import {
  GetFieldsDisplayName,
  getQueryAttribute,
  GetSelectedField,
  hideProgress,
  removeSessionStorage,
  showProgress,
} from "../../RAFComponents/helpers/AppHelper";
import {
  deepEqual,
  Guid,
  isNotNullAndUndefined,
  isNullOrUndefined,
  IsNullOrWhiteSpace,
} from "../../RAFComponents/helpers/utils";
import { StorageKey } from "../../constants/Common/Constants";
import { QueryAttributeJM, ValueJson } from "../models/Common/QueryAttributeJM";
import { RAFAttributesContext } from "../Providers/RAFAttributeRelatedListProvider";
import "./InputStyle.scss";
import ManageChoiceList from "./ManageChoiceList";
import RAFFieldLabel from "./RAFFieldLabel";
import { composeValidators, RAFFieldError } from "./RAFForm";
import {
  isRequired,
  RAFDefaultFieldClassName,
  RAFDefaultFieldProps,
  RAFDropdownFieldProps,
  RAFFormContext,
} from "./RFFUtils";

const getDataFromChildren = (
  children?,
  addEmpty?: boolean,
  emptyString?: string,
  attributeJM?: QueryAttributeJM
) => {
  let retVal: ValueJson[] = [];
  if (isNotNullAndUndefined(children) && children.length > 0) {
    if (isNotNullAndUndefined(addEmpty) && addEmpty === true) {
      retVal.push({
        Id: 0,
        Name: null,
        DisplayName: emptyString || "None",
        ColorCode: "#B3B6B7",
      });
    }
    React.Children.forEach(children, (child, i) => {
      //retVal.push({ ...child["props"], label: (child["props"]["children"] || child["props"]["label"]) });
      retVal.push({
        Id: i + 1,
        Name: child["props"]["value"],
        DisplayName: child["props"]["children"] || child["props"]["label"],
        ColorCode: child["props"]["colorCode"],
      });
    });
  } else {
    if (isNotNullAndUndefined(addEmpty) && addEmpty === true) {
      retVal.push({
        Id: 0,
        Name: "",
        DisplayName: emptyString || "None",
        ColorCode: "transparent",
      });
    }
    if (isNotNullAndUndefined(attributeJM)) {
      if (isNotNullAndUndefined(attributeJM.ValueJson)) {
        retVal.push(...attributeJM.ValueJson);
      }
    }
  }
  return retVal;
};

interface IProps {
  moduleName?: string;
  allowAdd?: boolean;
  emptyString?: string;
  queryAttribute?: QueryAttributeJM;
  disableItems?: any[];
  formGroupClassName?: string;
  createOptionMode?: "Footer" | "Default";
}

interface IState {
  showManageChoiceList: boolean;
  attributeJM: QueryAttributeJM;
  dropDownItem: ValueJson[];
}

class RAFComboBoxCC<T> extends React.Component<
  PropsWithChildren<RAFDropdownFieldProps<T> & IProps>,
  IState
> {
  static defaultProps: Partial<IProps>;

  _isMounted = false;

  private textField = isNotNullAndUndefined(this.props.textField)
    ? this.props.textField
    : "DisplayName";
  private valueField = isNotNullAndUndefined(this.props.valueField)
    ? this.props.valueField
    : "Name";

  private fields = {
    text: "DisplayName",
    value: "Name",
    itemCreated: (e) => {
      if (
        isNotNullAndUndefined(this.props.disableItems) &&
        this.props.disableItems.length > 0
      ) {
        this.props.disableItems.forEach((item) => {
          if (isNotNullAndUndefined(item) && item.value === e.curData.Name) {
            e.item.classList.add("e-disabled");
            e.item.classList.add("e-overlay");
          }
        });
      }
    },
  };

  private field = this.props.field.toString();
  private rafFormContextValue: FormRenderProps;
  private indexVal: string = Guid.newGuid();
  private comboBoxDropDownListComponent: ComboBoxComponent;

  constructor(props) {
    super(props);
    if (isNotNullAndUndefined(this.textField)) {
      this.fields.text = this.textField;
    }
    if (isNotNullAndUndefined(this.valueField)) {
      this.fields.value = this.valueField;
    }
    this.state = {
      showManageChoiceList: false,
      attributeJM: isNotNullAndUndefined(this.props.queryAttribute)
        ? this.props.queryAttribute
        : {},
      dropDownItem: [],
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
    this.fetchDropdowItems();
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    let dropDownItem = getDataFromChildren(
      React.Children.toArray(this.props.children),
      false,
      "",
      this.state.attributeJM
    );

    if (!deepEqual(prevState.dropDownItem, dropDownItem)) {
      this.fetchDropdowItems();
    }
  }

  fetchDropdowItems = () => {
    if (isNullOrUndefined(this.props.queryAttribute)) {
      getQueryAttribute(this.props.moduleName, this.field)
        .then((queryAttribute) => {
          if (this._isMounted) {
            this.updateStateValues(queryAttribute);
          }
        })
        .catch((error) => error);
    } else {
      if (this._isMounted) {
        this.updateStateValues(this.props.queryAttribute);
      }
    }
  };

  createModalToggle = (divId) => {
    let customDropdownMenu = document.querySelectorAll("." + divId);
    if (isNotNullAndUndefined(customDropdownMenu)) {
      customDropdownMenu.forEach((item) => {
        item.classList.remove("e-popup-open");
        item.classList.add("e-popup-close");
      });
    }
    this.openManageChoiceListDialog();
  };

  refreshDropdown = () => {
    if (this._isMounted) {
      this.setState({ showManageChoiceList: false }, () => {
        removeSessionStorage(
          StorageKey.allAttribs_modulename + this.props.moduleName,
          true
        );
        removeSessionStorage(
          StorageKey.viewAttribs_modulename + this.props.moduleName,
          true
        );
        if (isNullOrUndefined(this.props.queryAttribute)) {
          let progressDiv = showProgress("#rafdiv" + this.field);
          getQueryAttribute(this.props.moduleName, this.field)
            .then((queryAttribute) => {
              hideProgress(progressDiv);
              // let dropDownItem = getDataFromChildren(React.Children.toArray(this.props.children), false, '', queryAttribute);
              // if (isNotNullAndUndefined(this.rafFormContextValue) && isNotNullAndUndefined(this.rafFormContextValue.form) && isNotNullAndUndefined(this.rafFormContextValue.form.mutators)) {
              //     let selectedDropDownItem = this.rafFormContextValue.values[this.field];
              //     if (isNullOrUndefined(dropDownItem.find(x => x.DisplayName === selectedDropDownItem))) {
              //         this.rafFormContextValue.form.mutators.setValue(this.field, null);
              //     }
              // }
              this.updateStateValues(queryAttribute);
            })
            .catch((error) => error);
        }
      });
    }
  };

  updateStateValues = (attributeJM: QueryAttributeJM) => {
    if (this._isMounted) {
      let dropDownItem = getDataFromChildren(
        React.Children.toArray(this.props.children),
        false,
        "",
        attributeJM
      );
      this.setState({ attributeJM, dropDownItem });
    }
  };

  setValueDelay = (inputValue) => {
    if (isNotNullAndUndefined(inputValue) && !IsNullOrWhiteSpace(inputValue)) {
      setTimeout(() => {
        if (this.comboBoxDropDownListComponent) {
          this.comboBoxDropDownListComponent.value = inputValue;
        }
      }, 100);
    }
  };

  footerTemplate = () => {
    return (
      <div className="row g-0 justify-content-between border-top">
        <div className="col-auto d-flex">
          <ButtonComponent
            type="button"
            iconCss="fas fa-plus"
            className="link-button custom-link-btn"
            onClick={() => this.openManageChoiceListDialog()}
          >
            Add
          </ButtonComponent>
        </div>
      </div>
    );
  };

  showManageChoiceListContent = () => {
    if (this.state.showManageChoiceList === true) {
      return (
        <ManageChoiceList
          allowAdd
          field={this.field}
          onSave={() => this.refreshDropdown()}
          onClose={() => this.closeCreateDialog()}
          attributeJM={this.state.attributeJM}
        //moduleName={moduleName}
        ></ManageChoiceList>
      );
    } else {
      return <div></div>;
    }
  };

  openManageChoiceListDialog = () => {
    if (this._isMounted) {
      this.setState({ showManageChoiceList: true });
    }
  };

  closeCreateDialog = () => {
    if (this._isMounted) {
      this.setState({ showManageChoiceList: false });
    }
  };

  render() {
    let {
      field,
      initialValue,
      label,
      required,
      showLabel,
      width,
      validate,
      disabled,
      onChanged,
      description,
      validators,
      placeholder,
      showClearButton,
      allowFiltering,
      createOptionMode,
      allowAdd,
      descriptionAsLabel,
    } = this.props;
    let items = this.state.dropDownItem;
    let { showManageChoiceList } = this.state;
    let labelClassName = isNotNullAndUndefined(this.props.labelClassName)
      ? " " + this.props.labelClassName
      : "";

    return (
      <Fragment>
        <RAFFormContext.Consumer>
          {(rafFormContextValue) => {
            this.rafFormContextValue = rafFormContextValue;
            return (
              <div
                className={
                  isNotNullAndUndefined(this.props.formGroupClassName)
                    ? this.props.formGroupClassName + " form-group"
                    : "form-group"
                }
              >
                <div className={RAFDefaultFieldClassName.rowClassName} id={"rafdiv" + field.toString()}>
                  {showLabel && showLabel === true && (
                    <RAFFieldLabel
                      field={field}
                      label={label}
                      required={required}
                      labelClassName={labelClassName}
                      description={description}
                      descriptionAsLabel={descriptionAsLabel}
                    ></RAFFieldLabel>
                  )}
                  <div className="col-12">
                    <Field
                      name={field.toString()}
                      {...(initialValue ? { initialValue: initialValue } : {})}
                      {...(validators
                        ? {
                          validate:
                            validate === true
                              ? composeValidators(
                                required === true ? isRequired : null,
                                ...validators
                              )
                              : null,
                        }
                        : {
                          validate:
                            validate === true
                              ? composeValidators(
                                required === true ? isRequired : null
                              )
                              : null,
                        })}
                      allowNull
                      parse={(value) => (value === "" ? null : value)}
                    >
                      {({ input, meta }) => {
                        this.setValueDelay(input.value);
                        return (
                          <div>
                            <div className="row g-2">
                              <div className="col">
                                <ComboBoxComponent
                                  ref={(d) =>
                                    (this.comboBoxDropDownListComponent = d)
                                  }
                                  name={input.name}
                                  value={input.value}
                                  id={`cbc${field.toString()}inputField`}
                                  change={(e) => {
                                    if (e.isInteracted) {
                                      input.onChange(
                                        e.itemData !== null
                                          ? e.itemData[this.fields.value]
                                          : null
                                      );
                                      if (isNotNullAndUndefined(onChanged)) {
                                        onChanged(
                                          e.itemData !== null
                                            ? e.itemData[this.fields.text]
                                            : null,
                                          e.itemData !== null
                                            ? e.itemData[this.fields.value]
                                            : null
                                        );
                                      }
                                    }
                                  }}
                                  {...(createOptionMode === "Footer" &&
                                    allowAdd === true
                                    ? { footerTemplate: this.footerTemplate }
                                    : {})}
                                  dataSource={items as any}
                                  fields={this.fields}
                                  showClearButton={showClearButton}
                                  allowFiltering={allowFiltering}
                                  width={width}
                                  readonly={disabled}
                                  cssClass={
                                    meta.error && meta.touched
                                      ? "inputFieldError"
                                      : null
                                  }
                                  {...(showClearButton === true
                                    ? { showClearButton: showClearButton }
                                    : {})}
                                  placeholder={placeholder}
                                />
                              </div>
                              {createOptionMode === "Default" &&
                                allowAdd === true && (
                                  <div className="col-auto">
                                    <ButtonComponent
                                      id={`btnManageChoice_${field.toString()}_${this.indexVal
                                        }`}
                                      type="button"
                                      cssClass="e-flat icon-only default filterIcon"
                                      iconCss="fas fa-plus"
                                      onClick={() =>
                                        this.openManageChoiceListDialog()
                                      }
                                    ></ButtonComponent>
                                  </div>
                                )}
                            </div>

                            {this.props.hideRequiredMessage !== true ? (
                              <RAFFieldError name={field.toString()} />
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      }}
                    </Field>
                  </div>
                </div>
                {showManageChoiceList && (
                  <DialogComponent
                    header={"Manage : " + this.field}
                    visible={this.state.showManageChoiceList}
                    cssClass="centerDialog-sm choice-list-dialog" //ref={dialog => this.showLookupGridDialog = dialog}
                    content={this.showManageChoiceListContent.bind(this)}
                    showCloseIcon
                    isModal
                    target="body"
                    closeOnEscape={false}
                    close={this.closeCreateDialog.bind(this)}
                  ></DialogComponent>
                )}
              </div>
            );
          }}
        </RAFFormContext.Consumer>
      </Fragment>
    );
  }
}

RAFComboBoxCC.defaultProps = {
  ...RAFDefaultFieldProps,
  createOptionMode: "Default",
  allowAdd: false,
};
export default RAFComboBoxCC;
