import DOMPurify from 'dompurify';
import moment from "moment";
import { PropsWithChildren } from "react";
import { Field } from "react-final-form";
import { RAFDataType } from "../../RAFComponents/models/Common/RAFDataType";
import {
  convertUTCDateToLocalTimezone,
  isNotNullAndUndefined,
  IsNullOrWhiteSpace,
} from "../helpers/utils";
import "./InputStyle.scss";
import { RAFDefaultDetailFieldProps, RAFDetailFieldProps } from "./RFFUtils";

//const RAFDetailsField = ({ field, label, required }) => {

function RAFDetailsField<T>({
  field,
  fieldFormat,
  emptyString = RAFDefaultDetailFieldProps.emptyString,
  isSanitized = RAFDefaultDetailFieldProps.isSanitized,
  valueClassName = RAFDefaultDetailFieldProps.valueClassName,
}: PropsWithChildren<RAFDetailFieldProps<T>>) {
  return (
    <div>
      <Field name={field.toString()} id={"rafdiv" + field.toString()}>
        {({ input }) => {
          let inputValue = input.value;
          if (
            isNotNullAndUndefined(fieldFormat) &&
            !IsNullOrWhiteSpace(inputValue)
          ) {
            if (isNotNullAndUndefined(fieldFormat.type)) {
              if (fieldFormat.type === RAFDataType.Date) {
                // if (moment(inputValue, moment.ISO_8601, true).isValid()) {
                //   inputValue = moment(new Date(inputValue)).format(
                //     fieldFormat.format
                //   );
                // }
                let localDate = convertUTCDateToLocalTimezone(inputValue);
                inputValue = moment(localDate).format(fieldFormat.format);
              }
            }
          }
          return (
            <div>
              <span
                className={
                  valueClassName !== null
                    ? valueClassName
                    : "detailsValue"
                }
              >
                {IsNullOrWhiteSpace(inputValue) ? (
                  IsNullOrWhiteSpace(emptyString) ? (
                    "\u00A0"
                  ) : (
                    emptyString
                  )
                ) : isSanitized ? (
                  <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(inputValue) }}></div>
                ) : (
                  inputValue
                )}
              </span>
            </div>
          );
        }}
      </Field>
    </div>
  );
}

export default RAFDetailsField;
